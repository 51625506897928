import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import { PackageCard } from "../packages/packageCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import axios from "axios";
import { Loader } from "../general/spinner";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Link } from 'react-router-dom';
import { Searcher } from '../navbar/searcher';
import logoB from '../../assets/logos/logo1.png';
import { PrivRoutes } from '../../routes/routes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  font-style: normal;
  color: #474a4b;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }
`;

const DesktopLogo = styled.img`
  margin: auto;
  display: block;
  height: 40px;
  position: absolute;
  left: 20px;
  @media only screen and (max-width: 768px) {
    width: 50px;
  }
`;

const NormalText = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26.73px;
  color: #6a6a6a;
  text-align: left;
  margin: 0;
`;

const NormText = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: #4d99e5;
`;

const AddPackageButon = styled(Button)`
  max-width: 368px;
  max-height: 56px;
  padding-top: 12px;
  margin-top: 10px;
  padding-bottom: 12px;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  border-radius: 10px;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  .icon {
    fill: rgb(255, 255, 255);
  }
  :hover {
    .icon {
      fill: #4f90ba;
    }
    background: #fff;
    transition: 300ms;
    color: #4d99e5;
  }
`;

const NoPackages = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 20px;
  max-width: 944px;
  span {
    font-weight: 800;
  }
  img {
    width: 120%;
  }
  @media only screen and (max-width: 768px) {
    img {
      width: 100%;
    }
  }
  row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const MyPackages = () => {
  const [packages, setPackages] = useState([]);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var forPatient = urlParams?.get("forPatient") === "1";
  const [ showSearcher, setShowSearcher ] = useState(false);
  const history = useHistory();
  const uid = useSelector((state) => state?.auth?.uid);
  const [loading, setLoading] = useState(false);
  const [seeBegin, setSeeBegin] = useState(true);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const isDoctor =
  useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const mainColor = PrivRoutes.includes(location.pathname) && isDoctor ? "#4D99E5" : "#00AC90";

  const dd = async () => {
    try {
      setLoading(true);
      let requestPackages = await axios.get(
        `https://lpqsystem.com/getPaquetesMedico?uid=${uid}`
      );
      // console.log(requestPackages);
      if (requestPackages.data.ok) {
        setPackages(requestPackages.data.paquetes_medico);
        setLoading(false);
      } else {
        console.log("Error al obtener paquetes");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    console.log(id);
    try {
      console.log("deleting package...?");
      setLoading(true);
      let response = await axios.get(
        `https://lpqsystem.com/eliminarPaquete?id=${id}`
      );
      console.log(response);
      dd();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isDoctor) {
      window.location.href = "/perfil";
    }
    dd();
    // if (forPatient){
    //   setShowSearcher(true);
    // }
  }, []);

  useEffect(() => {
    if (packages.length > 0) {
      setSeeBegin(false);
    }
  }, [packages]);

  return (
    <Container
      style={{ minHeight: "100vh" }}
      className={width < 768 ? "mt-5 pt-4" : ""}
    >
      <HelmetTitle title="Paquetes" name="description" content="content" />

      <HeaderSection>
        <HeaderTitle>Paquetes de estudios</HeaderTitle>
      </HeaderSection>

      <NoPackages>
        <Row>
          {width >= 390 && (
            <Col lg={5}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fdashboard%2FLaptop.png?alt=media&token=4ec35377-bb99-4c68-9224-3fffaf4449a9&_gl=1*1ra5g19*_ga*MjAyMDAxODU4My4xNjg0NzgyMTc4*_ga_CW55HF8NVT*MTY4NTY1MDY3My42LjEuMTY4NTY1MDk2OC4wLjAuMA.."
                alt="medicos-paquetes"
              />
            </Col>
          )}
          <Col lg={7} id="text-col">
            <NormText>
              Personaliza paquetes de estudios de tus pacientes
            </NormText>
            <NormalText>
              Te invitamos a crear paquetes personalizados con los estudios que
              requieren tus pacientes. Al utilizar nuestra plataforma obtendrás
              precios descuentos especiales para ti.
            </NormalText>
            <AddPackageButon
              style={{ padding: "10px 40px", maxWidth: "250px" }}
              onClick={() => history.push("/paquetes")}
            >
              Crear nuevo paquete
            </AddPackageButon>
          </Col>
        </Row>
      </NoPackages>
      {packages?.length > 0 && (
        <>
          <Row className="mb-4">
            <NormalText>Paquetes creados</NormalText>
          </Row>
          <Row className="justify-content-center mb-5">
            {packages?.map((pack, index) => (
              <Col xs={12} key={index} className="mb-4">
                <PackageCard data={pack} onDelete={handleDelete} />
              </Col>
            ))}
          </Row>
        </>
      )}
      {loading ? <Loader show={loading} /> : null}
      <Modal
        show={showSearcher}
        fullscreen={true}
        onHide={() => setShowSearcher(true)}
      >
        <Modal.Body style={{ padding: "0px" }}>
          <Container>
            <div
              style={{
                width: "100%",
                paddingTop: "30px",
                minHeight: "100vh",
                position: "relative",
              }}
            >
              <Link to="/" replace>
                <DesktopLogo
                  alt="Logo Labopat"
                  src={logoB}
                  style={{ width: "100%", maxWidth: "164px", height: "auto" }}
                />{" "}
              </Link>
              <Searcher
                hideSearch={() => setShowSearcher(false)}
                spaceTop="100px"
                mainColor={mainColor}
                packages={packages}
                handleDelete={handleDelete}
              />
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
