export const academiaOptions = [
  // {
  //   id: 0,
  //   title: 'CURSO DE BIOLOGÍA MOLECULAR DE ALTA ESPECIALIDAD',
  //   desc: 'CURSO DE BIOLOGÍA MOLECULAR DE ALTA ESPECIALIDAD',
  //   link: 'https://www.cursopatologiamolecular.com/',
  // },
  {
    id: 1,
    name: "CURSO DE ALTA ESPECIALIDAD",
    description: "CURSO DE ALTA ESPECIALIDAD EN PATOLOGÍA MOLECULAR",
    link: "/curso-alta-especialidad",
  },
];

export const branchesOptions = [
  {
    id: 0,
    name: "PUEBLA",
    link: "/sucursales",
  },
  {
    id: 1,
    name: "AGUASCALIENTES",
    link: "/aguascalientes",
  },
];

export const branchesSesion = [
  {
    id: 0,
    name: "Entrar",
    link: "/dashboard",
  },
  {
    id: 1,
    name: "Cerrar sesión",
    link: "/aguascalientes",
  },
];

export const navbarMenu = [
  {
    name: "Estudios y servicios",
    link: "/estudios-servicios",
    arrow: true,
    submenu: [
      {
        name: "Covid / Enfermedades Respiratorias",
        link: "/covid-19",
        icon: "https://lpqsystem.com/labopat/logosNuevos/covid19.png",
        width: 40,
        description:
          "Servicio EXPRESS para la detección y seguimiento de la enfermedad causada por el virus SARS-CoV-2. Aval del InDRE con No. de Oficio DGE-DDYR-DSAT-3807-202.",
      },
      {
        name: "Toma de biopsia",
        link: "/toma-biopsia",
        icon: "https://lpqsystem.com/labopat/logosNuevos/biopsia.png",
        width: 45,
        description:
          "Toma de biopsias guiadas por ultrasonido con médicos especialistas en radiología.",
      },
      {
        name: "Nutrición",
        link: "/nutricion",
        icon: "https://lpqsystem.com/labopat/logosNuevos/nutricion.png",
        width: 40,
        description:
          "Nuestros servicios de nutrición integral tienen el objetivo de apoyarte a lograr un estilo de vida saludable.",
      },
      {
        name: "Laboratorio clínico",
        link: "/laboratorio-clinico",
        icon: "https://lpqsystem.com/labopat/logosNuevos/labclinicoe.png",
        width: 40,
        description:
          "Servicio de análisis eficiente, innovador y de confianza para la detección, tratamiento y prevención oportuna de patologías.",
      },
      {
        name: "Servicios genéticos",
        link: "/servicios-geneticos",
        icon: "https://lpqsystem.com/labopat/logosNuevos/geneticos.png",
        width: 40,
        description:
          "Pruebas genéticas para la detección de alteraciones causantes de trastornos o enfermedades.",
      },
      // {
      //   name: 'Check up',
      //   link: '/check-up',
      //   icon: 'https://lpqsystem.com/uploadC/checkup-icono-222004031957.png',
      //   width: 29,
      //   description:
      //     'Análisis preventivos que se realiza de forma rápida y segura para la detección oportuna de diversas enfermedades.',
      // },
      {
        name: "Patología quirúrgica",
        link: "/patologia-quirurgica",
        icon: "https://lpqsystem.com/labopat/logosNuevos/patologia.png",
        width: 40,
        description:
          "Brindamos un servicio integral a médicos y pacientes a través de nuestro equipo de médicos anatomopatólogos con gran experiencia y especialización.",
      },
      {
        name: "Ultrasonido",
        link: "/ultrasonido",
        icon: "https://lpqsystem.com/labopat/logosNuevos/ultrasonido.png",
        width: 40,
        description:
          "Ultrasonido especializado e integral con equipo de última generación.",
      },
      {
        name: "Biología molecular",
        link: "/molecular",
        icon: "https://lpqsystem.com/labopat/logosNuevos/molecular.png",
        width: 40,
        description:
          "El departamento de biología molecular realiza pruebas para la identificación de alteraciones genéticas causantes de diferentes patologías, incluido el cáncer y la identificación de genomas de microorganismos infecciosos.",
      },
      {
        name: "Rayos X",
        link: "/rayosx",
        icon: "https://lpqsystem.com/labopat/logosNuevos/RX.png",
        width: 40,
        description:
          "Esta información es un tremendísimo Lorem Ipsum en su máxima expresión, Lorem Ipsum just in case, Justin Case.",
      },
      // {
      //   name: 'Servicios a domicilio',
      //   link: '/servicios-domicilio',
      //   icon: 'https://lpqsystem.com/uploadC/domicilio-icono-222004111954.png',
      //   width: 32,
      //   description: 'Atención personalizada y eficiente sin salir de casa.',
      // },
    ],
  },
  {
    name: "Sucursales",
    link: "/sucursales",
    arrow: false,
  },
  {
    name: "Aguascalientes",
    link: "/aguascalientes",
    arrow: false,
  },
  // {
  //   name: "Blog",
  //   link: "/blog",
  //   arrow: false,
  // },
  {
    name: "Nosotros",
    link: "/nosotros",
    arrow: false,
  },
  //{
  //  name: 'Estudios rosas',
  //  link: '/estudiosrosas',
  //  arrow: false,
  // },
  {
    name: "Academia",
    link: "/curso-alta-especialidad",
    arrow: true,
    submenu: academiaOptions,
  },
  {
    name: "Médicos",
    link: "/medicos",
    arrow: false,
  },
  // {
  //   name: 'Membresias',
  //   link: '/membresias',
  //   arrow: false,
  // },
];
