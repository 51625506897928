export const containsIgnoreCase = (stringFull, shortString) => {
  const lowercasedStringFull = stringFull?.toLowerCase();
  const lowercasedShortString = shortString?.toLowerCase();

  return lowercasedStringFull?.includes(lowercasedShortString);
};

export const extractPlainText = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;

  document.body.appendChild(div);

  // Get the rendered text content
  const plainText = div.innerText;

  // Remove the temporary div
  document.body.removeChild(div);

  return plainText;
};