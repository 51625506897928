import React from 'react';
import styled from 'styled-components';
import { UilArrowRight } from '@iconscout/react-unicons';
import { CATEOGORIES, ORANGE } from './colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { timestampToSpanishDate } from '../../helpers/hours';
import { extractPlainText } from '../../helpers/any';

const StyledCard = styled.div`
  width: 100%;
  max-width: 306px;
  height: 529px;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #E9E9E9;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(71, 74, 75, 0.15);
  margin: 18px auto;
  position: relative;
  .img-div {
    width: 100%;
    height: 200px;
    background: ${({color, banner}) => banner ? `url('${banner}')` : color ?? '#00AC90'};
    background-position: center;
    background-size: cover;
    border-radius: 16px;
  }
  hr {
    width: 60px;
    color: ${({color}) => color};
    opacity: 1;
  }
  .content-div {
    padding: 0 23px 30px;
  }
  .category {
    color: ${({color}) => color};
    font-size: 16px;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.16px;
    margin-bottom: 5px;
  }
  .date {
    color: #C4C4C4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.16px;
    margin-bottom: 5px;
  }
  .title {
    color: #474A4B;
    font-family: IBM Plex Sans Condensed;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 129.63%;
    letter-spacing: 0.27px;
    margin-bottom: 10px;

    max-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .desc {
    color: #474A4B;
    font-family: IBM Plex Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
  .continue {
    padding: 7px 26px;
    background: #F2F7F9;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    transition: all ease 0.3s;
    :hover {
      background: ${({color}) => color};
      color: #fff;
    }
  }
`;

const ContentP = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 8em; /* Adjust as needed */
  line-height: 2em; /* Adjust as needed */
`;

export const NewBlogCardGrid = (props) => {
  const history = useHistory();

  // Extract plain text from HTML content (3 lines)
  const truncatedText = extractPlainText(props?.data?.content);

  const currentCat = CATEOGORIES.find(cat => cat?.id === props?.data?.category);

  return (
    <StyledCard color={currentCat?.color ?? '#00AC90'} banner={props?.data?.banner}>
      <div className='img-div'/>
      <hr />
      <div className='content-div'>
        <p className='category'>{currentCat?.name}</p>
        <p className='date'>{timestampToSpanishDate(props?.data?.created) ?? 'Sin fecha'}</p>
        <p className='title'>{props?.data?.title}</p>
        <ContentP className='desc'>{truncatedText}</ContentP>
      </div>
      <div className='continue' onClick={() => history.push(`/post/${props?.data?.id ?? 0}`)}>Seguir leyendo<UilArrowRight/></div>
    </StyledCard>
  );
};
