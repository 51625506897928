import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Background = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    #5097e3 -8.82%,
    #0cac8c 74.01%,
    #30df99 99.88%,
    #cef719 128.77%,
    #f5ec1d 138.96%
  );
`;

const Background1 = styled.div`
  width: 100%;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2Fbanner%20ags%202.png?alt=media&token=d48aed30-019f-406d-a5f4-bc93913b1eaf")
    center center/cover no-repeat;
`;

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  h1 {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-size: 56px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 18px;
  }
  p {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 56px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 43px;
    max-width: 548px;
  }
  #titlemob {
    font-family: "IBM Plex Sans";
    font-weight: 200;
    font-size: 40px;
    line-height: 100%;
    color: #000;

    max-width: 548px;
  }
  #subtitlemob {
    font-family: "IBM Plex Sans";
    font-weight: 200;
    font-size: 22px;
    line-height: 100%;
    color: #000;

    max-width: 548px;
  }
  #subtitlep {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 30px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 43px;
    max-width: 548px;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  img {
    max-height: 450px;
    @media only screen and (max-width: 768px) {
      max-height: 310px;
      position: absolute;
      bottom: 0px;
      left: 211px;
    }
    @media only screen and (max-width: 576px) {
      max-height: 310px;
      position: absolute;
      bottom: 0px;
      left: 172px;
    }
    @media only screen and (max-width: 390px) {
      max-height: 310px;
      position: absolute;
      bottom: 0px;
      left: 172px;
    }
    @media only screen and (max-width: 360px) {
      max-height: 310px;
      position: absolute;
      bottom: 0px;
      left: 142px;
    }
    @media only screen and (max-width: 340px) {
      max-height: 310px;
      position: absolute;
      bottom: 0px;
      left: 125px;
    }
  }
  @media only screen and (min-width: 1920px) {
    padding-left: 7%;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1920px) {
    padding-left: 5%;
  }
  @media only screen and (max-width: 992px) {
    height: 520px;
    h1 {
      font-size: 32px;
      margin-top: 15px;
    }
    p {
      font-size: 30px;
    }
    #img-col {
      height: 520px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 20px 0;
    h1 {
      font-size: 26px;
    }
    p {
      font-size: 20px;
    }
  }
`;

const TransparentButton = styled.button`
  padding: 5px 28px;
  border: 3px solid #cef719;
  background: none;
  border-radius: 10px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #cef719;
  margin-bottom: 25px;
  transition: all 0.4s ease;
  :hover {
    background: #fff;
    color: #00ac90;
  }
  @media only screen and (max-width: 1150px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 768px) {
    border: 3px solid #fff;
    font-size: 18px;
    color: #fff;
  }
`;

export const BannerRayosx = () => {
  const { width } = useWindowDimensions();
  let isMobile = width <= 576;
  return (
    <>
      {isMobile ? (
        <>
          <Background1>
            <Slide>
              <Row>
                <Col xs={12} md={7} id="text-col">
                  <p id="titlemob">
                    Servicio de <br />
                    <span style={{ fontWeight: "500" }}>
                      Rayos X
                    </span> ahora <br />
                    en <span style={{ fontWeight: "500" }}>Labopat®</span>
                  </p>
                  <p id="subtitlemob">
                    La tecnología más avanzada <br /> para un diagnóstico
                    confiable
                    <br />
                    está en <span style={{ fontWeight: "500" }}>Labopat®</span>
                  </p>
                  <TransparentButton
                    onClick={() => (window.location.href = "/registro")}
                  >
                    Crea una cuenta
                  </TransparentButton>
                </Col>
                <Col xs={12} md={5} xxl={5} id="img-col">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2Fdocmobile.png?alt=media&token=4feed3d6-fa2b-40a3-9327-3faef456962c"
                    alt="doctor"
                  />
                </Col>
              </Row>
            </Slide>
          </Background1>
        </>
      ) : (
        <>
          <Background>
            <Slide>
              <Row>
                <Col xs={12} md={7} id="text-col">
                  <h1>NUEVO</h1>
                  <p>
                    Servicio de <b>Rayos X</b>
                  </p>
                  <p id="subtitlep">
                    La tecnología más avanzada para un <br />
                    diagnóstico confiable está en <b>Labopat®</b>
                  </p>
                  <TransparentButton
                    onClick={() => (window.location.href = "/rayosx")}
                  >
                    Solicita un estudio
                  </TransparentButton>
                </Col>
                <Col xs={12} md={5} id="img-col">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2FAdobeStock_611181714%201.png?alt=media&token=e42686ab-ee94-44db-b71a-16818295c42e"
                    alt="doctor"
                  />
                </Col>
              </Row>
            </Slide>
          </Background>
        </>
      )}
    </>
  );
};
