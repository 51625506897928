/* eslint-disable no-unused-vars */
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import {
  UilBookMedical,
  UilCameraPlus,
  UilFacebookF,
  UilInstagram,
  UilGlobe,
} from "@iconscout/react-unicons";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useForm } from "../../hooks/useForm";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
import axios from "axios";
import { updateInfoUser } from "../../actions/user";
import { useSelector } from "react-redux";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import {
  getStorage,
  uploadBytes,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, app } from "../../firebase/firebase-config";
import { addMember } from "../../mailchimp/maichimp";
import { Fotoperfil } from "./Fotoperfil";

const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const especialidades = [
  { value: "Selecciona una opción" },
  { value: "MEDICINA GENERAL" },
  { value: "ALERGOLOGÍA" },
  { value: "ANATOMÍA PATOLÓGICA" },
  { value: "ANESTESIOLOGÍA" },
  { value: "AUDIOLOGÍA, OTONEUROLOGÍA Y FONIATRÍA" },
  { value: "CALIDAD DE LA ATENCIÓN CLÍNICA" },
  { value: "CIRUGÍA GENERAL" },
  { value: "EPIDEMIOLOGÍA" },
  { value: "GASTROENTEROLOGÍA" },
  { value: "GENÉTICA MÉDICA" },
  { value: "GERIATRÍA" },
  { value: "GINECOLOGÍA Y OBSTETRICIA" },
  { value: "IMAGENOLOGÍA DIAGNÓSTICA Y TERAPÉUTICA" },
  { value: "MEDICINA DE LA ACTIVIDAD FISICA Y DEPORTIVA" },
  { value: "MEDICINA DE REHABILITACIÓN" },
  { value: "MEDICINA DE URGENCIAS" },
  { value: "MEDICINA DEL TRABAJO Y AMBIENTAL" },
  { value: "MEDICINA FAMILIAR" },
  { value: "MEDICINA INTERNA" },
  { value: "MEDICINA LEGAL" },
  { value: "MEDICINA NUCLEAR E IMAGENOLOGÍA MOLECULAR" },
  { value: "MEDICINA PREVENTIVA" },
  { value: "NEUMOLOGÍA" },
  { value: "OFTALMOLOGÍA" },
  { value: "OTORRINOLARINGOLOGÍA Y CIRUGIA DE CABEZA Y CUELLO" },
  { value: "ONCOLOGÍA CLÍNICA" },
  { value: "PATOLOGÍA CLÍNICA" },
  { value: "PEDIATRÍA" },
  { value: "PSIQUIATRÍA" },
  { value: "RADIO ONCOLOGÍA" },
  { value: "TRAUMATOLOGÍA Y ORTOPEDIA" },
  { value: "ANESTESIOLOGÍA" },
  { value: "OTRA" },
];

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;

  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 40px;
  line-height: 130%;
  color: #474a4b;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const NormalText = styled.p`
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 26.73px;
  color: #45494a;
  text-align: left;
  margin: 0;
`;

const TabTitle = styled.a`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 0;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
  color: ${(props) => (props.selected ? "#474A4B" : "#697077")};
  padding-left: 0;
  padding-right: 3%;
  padding-bottom: 4px;
  margin-bottom: -2px;
  transition: 200ms;
  padding-bottom: 20px;
  :hover {
    color: ${(props) => (props.$isDoctor ? "#4D99E5" : "#00AC90")};
    transition: 200ms;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
`;

const TabIndicatorCol = styled(Col)`
  height: 10px;
  border-radius: 5px;
  margin-top: -5px;
  background: ${(props) =>
    props.selected && props.$isDoctor
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : props.selected &&
        "linear-gradient(83.81deg, #F7EE1B -32.78%, #35E19B 24.37%, #00AE8E 75.27%, #5097E3 138.67%)"};
`;

const StyledNav = styled.nav`
  border-bottom: 1px solid #c1d9d8;
`;

const StyledInput = styled(Form.Control)`
  background: rgba(77, 153, 229, 0.1);
  border: none;
  border-radius: 10px !important;
  height: 50px;
  padding-left: 20px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
  :focus {
    filter: drop-shadow(0px 0px 8px rgba(59, 209, 230, 0.6));
    box-shadow: none;
    border-color: #00000000;
    border-radius: 10px;
    background: #ffffff;
  }
`;

const BorderForPhoneInput = styled.div`
  display: flex;
  padding: 2px;
  width: 332px;
  height: 48px;
  margin: 6px 11px;
  border-radius: 10px;
  :focus-within {
    background: linear-gradient(
      245deg,
      rgb(247, 238, 27) -32.78%,
      rgb(53, 225, 155) 24.37%,
      rgb(0, 174, 142) 75.27%,
      rgb(80, 151, 227) 138.67%
    );
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 328px;
  height: 44px;
  left: 0px;
  top: 0px;
  background: #ecf5fc;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  :focus-within,
  input:focus {
    background: #fff;
  }
  .PhoneInputCountry {
    margin-right: 3%;
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputInput {
    border: none;
    background-color: #ecf5fc;
  }
`;

const StyledLabel = styled(FloatingLabel)`
  width: 100%;
  padding: 2px;
  border-radius: 12px;
  transition: all ease 0.5s;
  position: relative;
  .input-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
  .form-control {
    border: none;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
    max-height: 45px;
    padding: 0 0 0 20px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
  }
  .form-select {
    border: none;
    background-color: #f6f6f6;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
    max-height: 45px;
    padding: 0 0 0 40px !important;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -10em -10em;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: "IBM Plex Sans";
    transition: 300ms;
  }
  :active,
  :focus-within {
    background: linear-gradient(
      245deg,
      rgb(247, 238, 27) -32.78%,
      rgb(53, 225, 155) 24.37%,
      rgb(0, 174, 142) 75.27%,
      rgb(80, 151, 227) 138.67%
    );
    label {
      margin: 5px 0 0 35px;
      padding: 4px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const Birthday = styled(Row)`
  text-align: center;
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
    cursor: pointer;
    :focus {
      box-shadow: none;
      filter: drop-shadow(0px 0px 8px rgba(59, 209, 230, 0.6));
      border-radius: 10px;
      background: #ffffff;
      border-radius: 10px;
    }
  }
  .border-div {
    border-radius: 12px;
    padding: 12px 2px 2px;
  }
  .border-div:focus-within {
    background: linear-gradient(
      245deg,
      rgb(247, 238, 27) -32.78%,
      rgb(53, 225, 155) 24.37%,
      rgb(0, 174, 142) 75.27%,
      rgb(80, 151, 227) 138.67%
    );
  }
  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: -52px;
  }
`;

const StyledSelect = styled(Form.Select)`
  border-radius: 12px;
`;

const InputStyled = styled(Form.Check)`
  > input {
    width: 18px !important;
    border: ${(props) =>
      props.isdoctor === 1
        ? "1px solid #4D99E5 !important"
        : "1px solid #00AC90 !important"};
    :checked {
      border: ${(props) =>
        props.isdoctor === 1
          ? "1px solid #4D99E5 !important"
          : "1px solid #00AC90 !important"};
      background-color: ${(props) =>
        props.isdoctor === 1 ? "#4D99E5 !important" : "#00AC90 !important"};
    }
  }
`;

const Delimitador = styled.hr`
  width: 100%;
  padding: 0.5px 0;
  background: ${(props) => (props.isDoctor ? "#4D99E5" : "#00AC90")};
`;

const UploadImage = styled.div`
  width: 120px;
  height: 120px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #e0ecec;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LogoDocContainer = styled.div`
  border-radius: 50%;
  box-shadow: 0px 4px 4px #d6d6d6;
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;
  position: relative;
  overflow: hidden;
  background-image: ${(props) => `url('${props.bg}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
`;

const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #00ad90;
    text-decoration: underline;
  }
`;

export const EditPersonalData = (props) => {
  const [tab, setTab] = useState(0);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isNewUser = urlParams.get("new");
  const [persona, setPersona] = useState(1);
  const [necesitoFactura, setNecesitoFactura] = useState(0);

  const [regimen, setRegimen] = useState([
    { id: 1, value: "General de Ley Personas Morales", clave: "601", tipo: 2 },
    {
      id: 2,
      value: "Personas Morales con Fines no Lucrativos",
      clave: "603",
      tipo: 2,
    },
    {
      id: 3,
      value: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      clave: "605",
      tipo: 1,
    },
    { id: 4, value: "Arrendamiento", clave: "606", tipo: 1 },
    {
      id: 5,
      value: "Régimen de Enajenación o Adquisición de Bienes",
      clave: "607",
      tipo: 1,
    },
    { id: 6, value: "Demás ingresos", clave: "608", tipo: 1 },
    {
      id: 7,
      value:
        "Residentes en el Extranjero sin Establecimiento Permanente en México",
      clave: "610",
      tipo: 0,
    },
    {
      id: 8,
      value: "Ingresos por Dividendos (socios y accionistas)",
      clave: "611",
      tipo: 1,
    },
    {
      id: 9,
      value: "Personas Físicas con Actividades Empresariales y Profesionales",
      clave: "612",
      tipo: 1,
    },
    { id: 10, value: "Ingresos por intereses", clave: "614", tipo: 1 },
    {
      id: 11,
      value: "Régimen de los ingresos por obtención de premios",
      clave: "615",
      tipo: 1,
    },
    { id: 12, value: "Sin obligaciones fiscales", clave: "616", tipo: 1 },
    {
      id: 13,
      value:
        "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
      clave: "620",
      tipo: 2,
    },
    { id: 14, value: "Incorporación Fiscal", clave: "621", tipo: 1 },
    {
      id: 15,
      value: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
      clave: "622",
      tipo: 2,
    },
    {
      id: 16,
      value: "Opcional para Grupos de Sociedades",
      clave: "623",
      tipo: 2,
    },
    { id: 17, value: "Coordinados", clave: "624", tipo: 2 },
    {
      id: 18,
      value:
        "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      clave: "625",
      tipo: 1,
    },
    {
      id: 19,
      value: "Régimen Simplificado de Confianza",
      clave: "626",
      tipo: 0,
    },
  ]);
  const [cfdi, setcfdi] = useState([
    //{ id: 1,clave: "G01",nombre: "Adquisición de mercancías.",tipo: 0 },
    //{ id: 2,clave: "G02",nombre: "Devoluciones, descuentos o bonificaciones.",tipo: 0 },
    { id: 3, clave: "G03", value: "Gastos en general.", tipo: 0 },
    /*{ id: 4,clave: "I01",nombre: "Construcciones.",tipo: 0 },
      { id: 5,clave: "I02",nombre: "Mobiliario y equipo de oficina por inversiones.",tipo: 0 },
      { id: 6,clave: "I03",nombre: "Equipo de transporte.",tipo: 0 },
      { id: 7,clave: "I04",nombre: "Equipo de computo y accesorios.",tipo: 0 },
      { id: 8,clave: "I05",nombre: "Dados, troqueles, moldes, matrices y herramental.",tipo: 0 },
      { id: 9,clave: "I06",nombre: "Comunicaciones telefónicas.",tipo: 0 },
      { id: 10,clave: "I07",nombre: "Comunicaciones satelitales.",tipo: 0 },
      { id: 11,clave: "I08",nombre: "Otra maquinaria y equipo.",tipo: 0 },*/
    {
      id: 12,
      clave: "D01",
      value: "Honorarios médicos, dentales y gastos hospitalarios.",
      tipo: 1,
    },
    {
      id: 13,
      clave: "D02",
      value: "Gastos médicos por incapacidad o discapacidad.",
      tipo: 1,
    },
    { id: 14, clave: "D03", value: "Gastos funerales.", tipo: 1 },
    /*{ id: 15,clave: "D04",nombre: "Donativos.",tipo: 1 },
      { id: 16,clave: "D05",nombre: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",tipo: 1 },
      { id: 17,clave: "D06",nombre: "Aportaciones voluntarias al SAR.",tipo: 1 },*/
    {
      id: 18,
      clave: "D07",
      value: "Primas por seguros de gastos médicos.",
      tipo: 1,
    },
    /*{ id: 19,clave: "D08",nombre: "Gastos de transportación escolar obligatoria.",tipo: 1 },
      { id: 20,clave: "D09",nombre: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",tipo: 1 },
      { id: 21,clave: "D10",nombre: "Pagos por servicios educativos (colegiaturas).",tipo: 1 },*/
    { id: 22, clave: "S01", value: "Sin efectos fiscales.", tipo: 0 },
    /*{ id: 23,clave: "CP01",nombre: "Pagos",tipo: 0 },
      { id: 24,clave: "CN01",nombre: "Nómina",tipo: 1 },*/
  ]);

  const years = [];
  for (var i = 2024; i >= 1930; i--) {
    years.push(i.toString());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonStyle = {
    width: "214px",
    height: "48px",
    border: "none",
    borderRadius: "102px",
    fontweight: "800",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const text1Style = {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#000000",
  };

  const rowContent = {
    width: "100%",
  };

  const smallText = {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    textAlign: "left",
    color: "#A6A6A6",
    margin: "0 0 2px 0",
  };

  // const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const isMobile = width < 630;

  // Go back arrow
  const goBackLabel = {
    ...text1Style,
    width: "40px",
    margin: width < 680 ? "0px -29px" : "0px -15px",
  };

  const subtitle = (text) => (
    <Row
      style={{
        ...rowContent,
        paddingLeft: isMobile ? "7%" : "4.1%",
        margin: "10px 0",
      }}
    >
      <br />
      <p style={{ ...goBackLabel, textAlign: "left", width: "100%" }}>{text}</p>
    </Row>
  );

  const colBirthDay = (array, title, defaultValue, id) => (
    <Col xs={4} style={{ paddingLeft: "0px" }}>
      <Form.Label>{title}</Form.Label>
      <div className="border-div">
        <Form.Select
          defaultValue={defaultValue}
          key={id}
          id={id}
          name={id}
          onChange={handleInputChange}
        >
          <option disabled hidden>
            {defaultValue}
          </option>
          {array.map((item, i) => (
            <option key={`${item}-${i}`} value={item}>
              {item}
            </option>
          ))}
          {/* studies/studiesDetail */}
        </Form.Select>
      </div>
    </Col>
  );

  const user = useSelector((state) => state.user?.data?.data);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [addSecondEmail, setAddSecondEmail] = useState(false);
  const uidProfile = useSelector((state) => state.auth.uid);
  const [addPhoneContact, setAddPhoneContact] = useState(false);
  const [addInnerNumber, setAddInnerNumber] = useState(false);
  const [addOfficeNumberOrName, setAddOfficeNumberOrName] = useState(false);
  const [addExtensionNumber, setAddExtensionNumber] = useState(false);
  const [newsLetterCheck, setChecked] = useState(
    user?.newsLetterCheck ? user.newsLetterCheck : false
  );
  const [imDr, setImDr] = useState(user?.profile?.type == 1 ? true : false);
  const [gender, setRadioValue] = useState(
    user?.gender ? user.gender : "Masculino"
  );
  const [picture, setPicture] = useState(
    user?.profile?.logo ? user.profile.logo : ""
  );
  const [prevPicture, setPrevPicture] = useState("");
  const myRef = useRef();

  const [imageUrl, setImageUrl] = useState("");

  // Función para obtener la imagen del almacenamiento local al cargar la página
  useEffect(() => {
    const storedImageUrl = localStorage.getItem("storedImageUrl");
    if (storedImageUrl) {
      setImageUrl(storedImageUrl);
    }
  }, []);

  // Función para manejar el cambio de la imagen desde el explorador de archivos
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageUrl = reader.result;
      setImageUrl(imageUrl);
      localStorage.setItem("storedImageUrl", imageUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const addWhat = (title) => {
    switch (title) {
      case "Agregar otro correo":
        setAddSecondEmail(!addSecondEmail);
        break;
      case "Agregar otro teléfono de contacto":
        setAddPhoneContact(!addPhoneContact);
        break;
      case "Añadir número interior":
        setAddInnerNumber(!addInnerNumber);
        break;
      case "Añadir número o nombre del consultorio":
        setAddOfficeNumberOrName(!addOfficeNumberOrName);
        break;
      case "Añadir número de extensión":
        setAddExtensionNumber(!addExtensionNumber);
        break;
      default:
        console.log("Nada que agregar");
    }
  };

  const checkInput = (id, label, value) => (
    <InputStyled
      key={id}
      id={id}
      type="radio"
      name={id}
      label={label}
      value={value}
      style={{ margin: "10px 8% 10px 0" }}
      checked={gender === value}
      isdoctor={isDoctor ? 1 : 0}
      onChange={(e) => setRadioValue(e.currentTarget.value)}
    />
  );

  const addAnotherSomething = (title) => (
    <Row
      key={title}
      onClick={() => addWhat(title)}
      className="mb-4 pb-2"
      style={{ cursor: "pointer", maxWidth: "380px" }}
    >
      <UilBookMedical
        className="w-auto"
        size="26"
        color={isDoctor ? "#4D99E5" : "#00AD90"}
      />
      <p className="w-auto p-0 m-0">{title}</p>
    </Row>
  );

  const newsletterCheckBox = (
    <InputStyled
      key="check-newsletter"
      id="check-newsletter"
      type="checkbox"
      name="radio"
      label="Quiero recibir noticias y promociones exclusivas"
      value={newsLetterCheck}
      style={{ margin: "10px 0" }}
      checked={newsLetterCheck}
      isdoctor={isDoctor ? 1 : 0}
      onChange={() => setChecked(!newsLetterCheck)}
    />
  );

  const [phone, setPhone] = useState(user?.phone ? user.phone : "");
  const [phone2, setPhone2] = useState(user?.phone2 ? user.phone2 : "");
  const [phoneNumberConsultingRoom, setPhoneConsultRoom] = useState(
    user?.phoneNumberConsultingRoom ? user.phoneNumberConsultingRoom : ""
  );
  const [phoneNumberEmergency, setPhoneEmergency] = useState(
    user?.phoneNumberEmergency ? user.phoneNumberEmergency : ""
  );
  const initialForm = {
    idMedicoLabopat: user?.profile?.idMedicoLabopat
      ? user?.profile?.idMedicoLabopat
      : 0,
    idPacienteLabopat: user?.profile?.idPacienteLabopat
      ? user?.profile?.idPacienteLabopat
      : 0,
    newUser: user?.newUser ? user?.newUser : false,
    name: user?.name ? user.name : "",
    fathersLastname: user?.fathersLastname ? user.fathersLastname : "",
    mothersLastname: user?.mothersLastname ? user.mothersLastname : "",
    email: user?.email ? user.email : "",
    secondEmail: user?.secondEmail ? user.secondEmail : "",
    street: user?.street ? user.street : "",
    next: user?.externalNumber ? user.externalNumber : "",
    cp: user?.cp ? user.cp : "",
    colonia: user?.colonia ? user.colonia : "",
    facebook: user?.facebook ? user.facebook : "",
    instagram: user?.instagram ? user.instagram : "",
    web: user?.web ? user.web : "",
    day: user?.birthday ? user.birthday.split("-")[2] : "",
    month: user?.birthday ? user.birthday.split("-")[1] : "",
    year: user?.birthday ? user.birthday.split("-")[0] : "",
    especialidad: user?.profile?.especialidad ? user?.profile.especialidad : "",
    next: user?.next ? user.next : "",
    nint: user?.nint ? user.nint : "",
    extensionNumber: user?.extensionNumber ? user.extensionNumber : "",
    officeNameOrNumber: user?.officeNameOrNumber ? user.officeNameOrNumber : "",
    num_consejo: user?.profile?.num_consejo ? user?.profile.num_consejo : "",
    otra_especialidad: user?.profile.otra_especialidad
      ? user?.profile.otra_especialidad
      : "",
    cedula_especialidad: user?.profile?.cedula_especialidad
      ? user?.profile?.cedula_especialidad
      : "",
    cedula_profesional: user?.profile?.cedula_profesional
      ? user?.profile?.cedula_profesional
      : "",
    universidad: user?.profile?.universidad ? user?.profile?.universidad : "",
    universidadB: user?.profile?.universidadB
      ? user?.profile?.universidadB
      : ".",
    rfc: user?.rfc ? user.rfc : "",
    razon: user?.razon ? user.razon : "",
    emailFac: user?.emailFac ? user.emailFac : "",
    calleFac: user?.calleFac ? user.calleFac : "",
    extFac: user?.extFac ? user.extFac : "",
    intFac: user?.intFac ? user.intFac : "",
    cpFac: user?.cpFac ? user.cpFac : "",
    coloniaFac: user?.coloniaFac ? user.coloniaFac : "",
    municipioFac: user?.municipioFac ? user.municipioFac : "",
    estadoFac: user?.estadoFac ? user.estadoFac : "",
    paisFac: user?.paisFac ? user.paisFac : "",
    usoFac: user?.usoFac ? user.usoFac : "",
    cfdiFac: user?.cfdiFac ? user.cfdiFac : "",
    obsFac: user?.obsFac ? user.obsFac : "",
  };

  const [formValues, handleInputChange] = useForm(initialForm);
  const {
    num_consejo,
    cedula_especialidad,
    cedula_profesional,
    universidad,
    universidadB,
    name,
    fathersLastname,
    mothersLastname,
    email,
    secondEmail,
    street,
    cp,
    colonia,
    facebook,
    instagram,
    web,
    day,
    month,
    year,
    next,
    nint,
    officeNameOrNumber,
    extensionNumber,
    especialidad,
    otra_especialidad,
    newUser,
    idPacienteLabopat,
    idMedicoLabopat,
    rfc,
    razon,
    emailFac,
    calleFac,
    extFac,
    intFac,
    cpFac,
    coloniaFac,
    municipioFac,
    estadoFac,
    paisFac,
    usoFac,
    cfdiFac,
    obsFac,
  } = formValues;

  const dispatch = useDispatch();

  const createMedicald = async (data) => {
    return await axios.post("https://lpqsystem.com/nuevoMe", data);
  };
  const createPatientId = async (data) => {
    console.log("paciente", data);
    var form = new FormData();
    form.append("nombre", data.info.nombre);
    form.append("app", data.info.app);
    form.append("apm", data.info.apm);
    form.append("tel", data.info.tel);
    form.append("email", data.info.email);
    form.append("sexo", "Femenino");
    form.append("fnac", data.info.fnac);
    form.append("edad", 45);
    form.append("uid", data.uid);
    console.log("***********...");

    return await axios.post("https://lpqsystem.com/crea-paciente", form);
    /*
    return await axios({
      method: 'post',
      headers: { 'content-type': 'application/json' },
      url: 'http://lpqsystem.com:3010/lpq/insert-paciente-principal',
      data: data,
    });*/
  };

  const handleUpdateProfileData = async () => {
    if (newsLetterCheck) {
      await addMember(formValues.email, isDoctor || imDr);
    }
    var idLpqMed = 0;
    var idLpqPac = 0;
    var promises = [];
    console.log("idLpq: ", formValues.idMedicoLabopat);
    if (imDr && formValues.idMedicoLabopat === 0) {
      var form = new FormData();
      form.append("nombre", formValues.name);
      form.append("app", formValues.fathersLastname);
      form.append("apm", formValues.mothersLastname);
      form.append("calle", formValues.street);
      form.append("noext", formValues.next);
      form.append("noint", formValues.nint);
      form.append("colonia", formValues.colonia);
      form.append("facebook", formValues.facebook);
      form.append("instagram", formValues.instagram);
      form.append("web", formValues.web);
      form.append("ciudad", "");
      form.append("estado", "");
      form.append("cp", formValues.cp);
      form.append("tel", "");
      form.append("email", formValues.email);
      const prom1 = createMedicald(form);
      promises.push(prom1);
      /*
      if (request.data.ok) {
        idLpqMed = request.data.id;
      } else {
        idLpqMed = 12;
      }*/
    } else {
      idLpqMed = formValues.idMedicoLabopat;
    }
    console.log("user: ", formValues.idPacienteLabopat);
    if (
      formValues.idPacienteLabopat === 0 ||
      formValues.idPacienteLabopat === -1
    ) {
      console.log("------");
      const birthday = `${formValues.year}-${formValues.month}-${formValues.day}`;
      let lpqData = {
        info: {
          nombre: formValues.name,
          app: formValues.fathersLastname,
          apm: formValues.mothersLastname,
          calle: formValues.calle,
          noext: formValues.noext,
          noint: formValues.noint,
          colonia: formValues.colonia,
          facebook: formValues.facebook,
          instagram: formValues.instagram,
          web: formValues.web,
          ciudad: formValues.ciudad,
          estado: formValues.estado,
          cp: formValues.cp,
          tel: formValues.tel,
          email: formValues.email,
          fnac: birthday,
          gender: formValues.gender,
        },
        uid: uidProfile,
      };
      const prom2 = createPatientId(lpqData);
      promises.push(prom2);
    } else {
      // checar mandar update a LPQ system
      console.log("ya tengo id");
      idLpqPac = formValues.idPacienteLabopat;
    }

    if (promises.length > 0) {
      let a = await Promise.all(promises);
      console.log(a);
      a.forEach((element) => {
        console.log(element.config.url);
        if (element.config.url === "https://lpqsystem.com/nuevoMe") {
          if (element.data.ok) {
            idLpqMed = element.data.id;
          } else {
            idLpqMed = 12;
          }
        } else if (
          element.config.url === "https://lpqsystem.com/crea-paciente"
        ) {
          if (element.data.ok) {
            idLpqPac = element.data.id;
          } else {
            idLpqPac = 0;
          }
        }
      });
      const birthday = `${formValues.year}-${formValues.month}-${formValues.day}`;
      var profile = user?.profile ? user?.profile : { type: imDr ? 1 : 0 };
      profile.idMedicoLabopat = idLpqMed;
      profile.idPacienteLabopat = idLpqPac;
      profile.type = imDr ? 1 : 0;
      profile.first_time = profile?.first_time ? false : true;
      profile.cedula_especialidad = cedula_especialidad;
      profile.cedula_profesional = cedula_profesional;
      profile.universidad = universidad;
      profile.universidadB = universidadB;
      profile.num_consejo = num_consejo;
      profile.otra_especialidad = otra_especialidad;
      profile.especialidad = especialidad;
      profile.first_time = false;
      profile.verifed = true;
      profile.logo =
        picture && picture.length > 20
          ? picture
          : "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdrLogos%2F0DedaultLogo.png?alt=media&token=719fdda6-4221-4c31-a9cd-82243eea531b";
      console.log("profile2: ", profile);

      const newObj = {
        ...formValues,
        birthday,
        phone,
        phoneNumberConsultingRoom,
        phoneNumberEmergency,
        gender,
        newsLetterCheck,
        phone2,
        profile,
      };
      delete newObj.day;
      delete newObj.month;
      delete newObj.year;
      console.log("f nac", newObj);

      dispatch(updateInfoUser(newObj));
      history.push("/datos-personales");
    } else {
      const birthday = `${formValues.year}-${formValues.month}-${formValues.day}`;
      var profile = user?.profile ? user?.profile : { type: imDr ? 1 : 0 };
      profile.idMedicoLabopat = idLpqMed;
      profile.idPacienteLabopat = idLpqPac;
      profile.type = imDr ? 1 : 0;
      profile.first_time = profile?.first_time ? false : true;
      profile.cedula_especialidad = cedula_especialidad;
      profile.cedula_profesional = cedula_profesional;
      profile.universidad = universidad;
      profile.universidadB = universidadB;
      profile.num_consejo = num_consejo;
      profile.otra_especialidad = otra_especialidad;
      profile.especialidad = especialidad;
      profile.first_time = false;
      profile.verifed = true;
      profile.logo =
        picture && picture.length > 20
          ? picture
          : "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdrLogos%2F0DedaultLogo.png?alt=media&token=719fdda6-4221-4c31-a9cd-82243eea531b";
      console.log("profile: ", profile);

      const newObj = {
        ...formValues,
        birthday,
        phone,
        phoneNumberConsultingRoom,
        phoneNumberEmergency,
        gender,
        newsLetterCheck,
        phone2,
        profile,
      };
      delete newObj.day;
      delete newObj.month;
      delete newObj.year;
      console.log(newObj);

      dispatch(updateInfoUser(newObj));
      history.push("/dashboard");
    }
  };

  const handleInputFile = () => {
    document.getElementById("inputFile").click();
  };

  const editInputFile = () => {
    setPrevPicture(picture);
    setPicture("");
    setTimeout(() => {
      document.getElementById("inputFile").click();
    }, 10);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      dispatch(
        uploadFile(file, `/ecommerce/drLogos/${file.name}${Date.now()}`)
      );
    }
  };

  const uploadFile = (file, path) => {
    const storageRef = getStorage(app, "gs://covid19-cc1fe.appspot.com/");
    const fileRef = ref(storageRef, path);
    uploadBytes(fileRef, file)
      .then((res) => {
        console.log(res);
        getDownloadURL(fileRef)
          .then((url) => {
            if (prevPicture !== "") {
              const refToDelete = ref(storageRef, prevPicture);
              deleteObject(refToDelete)
                .then(() => {
                  console.log("File deleted successfully");
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            console.log(url);
            setPicture(url);
          })
          .catch((error) => {
            console.log("Error en URL");
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container
      className={isMobile ? "mt-5 pt-4" : ""}
      style={{ minHeight: "100vh" }}
    >
      <HelmetTitle title="Mi perfil" name="description" content="content" />
      <Row className="mb-5">
        <HeaderSection>
          <HeaderTitle className="mb-5">
            {isNewUser && isNewUser === "1"
              ? "Completa tú perfil"
              : "Mi perfil"}
          </HeaderTitle>
          <StyledNav>
            <Col xs={6} md={3} lg={3} xl={2} style={{ paddingLeft: "0" }}>
              <TabTitle
                onClick={() => setTab(0)}
                selected={tab === 0 ? true : false}
                $isDoctor={isDoctor}
              >
                Datos generales
              </TabTitle>
            </Col>
            {/* <Col xs={6} md={3} lg={3} xl={2} style={{paddingLeft: '0'}}>
              <TabTitle
                onClick={() => setTab(0)} selected={tab === 1 ? true : false}>
                Peniente...
              </TabTitle>
            </Col> */}
          </StyledNav>
          <Row>
            <TabIndicatorCol
              xs={6}
              md={3}
              lg={3}
              xl={2}
              style={{ paddingLeft: "0" }}
              selected={tab === 0 ? true : false}
              $isDoctor={isDoctor}
            />
          </Row>
        </HeaderSection>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Row className="w-100 mb-4 pb-2">
            <p>
              {isNewUser && isNewUser === "1"
                ? "Al completar tu perfil médico, vamos a poder generar estadísticas y guardar tu historial médico."
                : ""}
            </p>
            <StyledLabel controlId="name" label="Nombre">
              <StyledInput
                type="text"
                placeholder="Nombre *"
                value={name}
                name="name"
                autoComplete="off"
                list="autocompleteOff"
                onChange={handleInputChange}
                required
              />
            </StyledLabel>
          </Row>
          <Row className="w-100 mb-4 pb-2">
            <StyledLabel controlId="fathersLastname" label="Apellido paterno">
              <StyledInput
                type="text"
                placeholder="Apellido paterno *"
                value={fathersLastname}
                name="fathersLastname"
                autoComplete="off"
                list="autocompleteOff"
                onChange={handleInputChange}
              />
            </StyledLabel>
          </Row>
          <Row className="w-100 mb-4 pb-2">
            <StyledLabel controlId="mothersLastname" label="Apellido materno">
              <StyledInput
                type="text"
                placeholder="Apellido materno *"
                value={mothersLastname}
                name="mothersLastname"
                autoComplete="off"
                list="autocompleteOff"
                onChange={handleInputChange}
              />
            </StyledLabel>
          </Row>
          <Row className="w-100 mb-4 pb-2">
            <StyledLabel controlId="email" label="Correo electrónico">
              <StyledInput
                type="email"
                placeholder="Correo electrónico*"
                value={email}
                name="email"
                autoComplete="off"
                list="autocompleteOff"
                onChange={handleInputChange}
              />
            </StyledLabel>
          </Row>
          {(addSecondEmail || secondEmail.length > 0) && (
            <Row className="w-100 mb-4 pb-2">
              <StyledLabel controlId="secondEmail" label="Correo adicional">
                <StyledInput
                  type="email"
                  placeholder="Correo adicional"
                  value={secondEmail}
                  name="secondEmail"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                />
              </StyledLabel>
            </Row>
          )}
          {!addSecondEmail &&
            !(secondEmail.length > 0) &&
            addAnotherSomething("Agregar otro correo")}
          <Row className="mb-4 pb-2">
            {/* {phoneInputComp('phoneNumber')} */}
            <Form.Label style={{ ...smallText, paddingLeft: "1.8%" }}>
              Teléfono celular
            </Form.Label>
            <BorderForPhoneInput>
              <StyledPhoneInput
                type="tel"
                international
                defaultCountry="MX"
                placeholder="Teléfono celular *"
                value={phone}
                name="phoneNumber"
                id="phoneNumber"
                autoComplete="off"
                list="autocompleteOff"
                onChange={setPhone}
              />
            </BorderForPhoneInput>
          </Row>
          {(addPhoneContact || phone2.length > 0) && (
            <Row className="mb-4 pb-2">
              {/* {phoneInputComp('phoneNumber')} */}
              <Form.Label style={{ ...smallText, paddingLeft: "1.8%" }}>
                Contacto adicional
              </Form.Label>
              <StyledPhoneInput
                type="tel"
                international
                defaultCountry="MX"
                placeholder="Contacto adicional"
                value={phone2}
                name="secondPhoneNumber"
                id="secondPhoneNumber"
                autoComplete="off"
                list="autocompleteOff"
                onChange={setPhone2}
              />
            </Row>
          )}
          {!addPhoneContact &&
            !(phone2.length > 0) &&
            addAnotherSomething("Agregar otro teléfono de contacto")}

          {subtitle("Fecha de nacimiento")}
          <Row className="mb-3">
            <div
              style={{
                width: "100%",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Birthday>
                {colBirthDay(days, "Día", day?.length > 0 ? day : "DD", "day")}
                {colBirthDay(
                  months,
                  "Mes",
                  month?.length > 0 ? month : "MM",
                  "month"
                )}
                {colBirthDay(
                  years,
                  "Año",
                  year?.length > 0 ? year : "AAAA",
                  "year"
                )}
              </Birthday>
            </div>
          </Row>
          {subtitle("Selecciona tu sexo")}
          <Row style={{ margin: "7px 0 0 0", padding: "3%" }}>
            <ButtonGroup className="mb-2" style={{ background: "#FFFFFF" }}>
              {checkInput("radio-masculino", "Masculino", "Masculino")}
              {checkInput("radio-femenino", "Femenino", "Femenino")}
            </ButtonGroup>
          </Row>

          <>
            <br />

            <Row className="mb-4 pb-2" style={{ paddingLeft: "3%" }}>
              <InputStyled
                key="check-soyMedico"
                id="check-newsletter"
                type="checkbox"
                name="necesitofactura"
                label="Necesito factura *"
                value={necesitoFactura}
                checked={necesitoFactura}
                onChange={() => setNecesitoFactura(!necesitoFactura)}
              />
            </Row>
          </>

          {necesitoFactura ? (
            <>
              <br />
              <h1 style={{ textAlign: "center", fontSize: "20px" }}>
                Datos de facturación
              </h1>
              <StyledLabel controlId="floatingRFC" label="RFC *">
                <StyledInput
                  type="text"
                  placeholder="RFC *"
                  value={rfc}
                  name="rfc"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={(e) => {
                    handleInputChange(e);
                    var valor = e.target.value;
                    console.log(valor);
                    if (isNaN(valor[3])) {
                      setPersona(1);
                      console.log("fisica");
                    } else {
                      setPersona(2);
                      console.log("moral");
                    }
                  }}
                  required
                />
              </StyledLabel>
              <br />
              <StyledLabel
                controlId="floatingName"
                label="Nombre/razón social *"
              >
                <StyledInput
                  type="text"
                  placeholder="Nombre/razón social *"
                  value={razon}
                  name="razon"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                  required
                />
              </StyledLabel>
              <br />
              <StyledLabel
                controlId="floatingInput"
                label="Correo electrónico *"
                className="mb-3"
              >
                <StyledInput
                  type="text"
                  placeholder="Correo electrónico *"
                  value={emailFac}
                  name="emailFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                  required
                />
              </StyledLabel>

              <StyledLabel>Regimen fiscal *</StyledLabel>
              <StyledSelect
                name="usoFac"
                value={usoFac}
                onChange={(e) => {
                  handleInputChange(e);
                  if (e.target.value == "605") {
                    setcfdi([
                      {
                        id: 12,
                        clave: "D01",
                        nombre:
                          "Honorarios médicos, dentales y gastos hospitalarios.",
                        tipo: 1,
                      },
                      {
                        id: 13,
                        clave: "D02",
                        nombre:
                          "Gastos médicos por incapacidad o discapacidad.",
                        tipo: 1,
                      },
                      {
                        id: 14,
                        clave: "D03",
                        nombre: "Gastos funerales.",
                        tipo: 1,
                      },
                      {
                        id: 18,
                        clave: "D07",
                        nombre: "Primas por seguros de gastos médicos.",
                        tipo: 1,
                      },
                      {
                        id: 22,
                        clave: "S01",
                        nombre: "Sin efectos fiscales.",
                        tipo: 0,
                      },
                    ]);
                  } else if (e.target.value == "610") {
                    setcfdi([
                      {
                        id: 22,
                        clave: "S01",
                        nombre: "Sin efectos fiscales.",
                        tipo: 0,
                      },
                    ]);
                  } else {
                    setcfdi([
                      {
                        id: 3,
                        clave: "G03",
                        nombre: "Gastos en general.",
                        tipo: 0,
                      },
                      {
                        id: 12,
                        clave: "D01",
                        nombre:
                          "Honorarios médicos, dentales y gastos hospitalarios.",
                        tipo: 1,
                      },
                      {
                        id: 13,
                        clave: "D02",
                        nombre:
                          "Gastos médicos por incapacidad o discapacidad.",
                        tipo: 1,
                      },
                      {
                        id: 14,
                        clave: "D03",
                        nombre: "Gastos funerales.",
                        tipo: 1,
                      },
                      {
                        id: 18,
                        clave: "D07",
                        nombre: "Primas por seguros de gastos médicos.",
                        tipo: 1,
                      },
                      {
                        id: 22,
                        clave: "S01",
                        nombre: "Sin efectos fiscales.",
                        tipo: 0,
                      },
                    ]);
                  }
                }}
              >
                <option disabled>Selecciona</option>
                {regimen.map((usoFac) =>
                  persona === 1 ? (
                    usoFac.tipo === 1 || usoFac.tipo === 0 ? (
                      <option value={usoFac.clave}>
                        {usoFac.clave} {usoFac.value}
                      </option>
                    ) : (
                      <></>
                    )
                  ) : persona === 2 ? (
                    usoFac.tipo === 2 || usoFac.tipo === 0 ? (
                      <option value={usoFac.clave}>
                        {usoFac.clave} {usoFac.nombre}
                      </option>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                )}
              </StyledSelect>
              <br />
              <StyledLabel controlId="floatingCalle1" label="Calle *">
                <StyledInput
                  type="text"
                  placeholder="Calle *"
                  value={calleFac}
                  name="calleFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                  required
                />
              </StyledLabel>
              <br />
              <StyledLabel controlId="floatingNext1" label="No. Exterior *">
                <StyledInput
                  type="text"
                  placeholder="No. Exterior *"
                  value={extFac}
                  name="extFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                  required
                />
              </StyledLabel>
              <br />
              <StyledLabel controlId="floatingNint1" label="No. Interior *">
                <StyledInput
                  type="text"
                  placeholder="No. Interior *"
                  value={intFac}
                  name="intFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                  required
                />
              </StyledLabel>
              <br />
              <StyledLabel controlId="floatingCP1" label="C.P. *">
                <StyledInput
                  type="text"
                  placeholder="C.P. *"
                  value={cpFac}
                  name="cpFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                  required
                />
              </StyledLabel>
              <br />
              <StyledLabel controlId="floatingColonia1" label="Colonia *">
                <StyledInput
                  type="text"
                  placeholder="Colonia *"
                  value={coloniaFac}
                  name="coloniaFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                  required
                />
              </StyledLabel>
              <br />
              <StyledLabel controlId="floatingMunicipio1" label="Municipio *">
                <StyledInput
                  type="text"
                  placeholder="Municipio *"
                  value={municipioFac}
                  name="municipioFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                />
              </StyledLabel>
              <br />
              <StyledLabel controlId="floatingEstado1" label="Estado *">
                <StyledInput
                  type="text"
                  placeholder="Estado *"
                  value={estadoFac}
                  name="estadoFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                />
              </StyledLabel>
              <br />
              <StyledLabel controlId="floatingPais1" label="País *">
                <StyledInput
                  type="text"
                  placeholder="País *"
                  value={paisFac}
                  name="paisFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                />
              </StyledLabel>
              <br />
              <StyledLabel>Uso de la factura *</StyledLabel>
              <StyledSelect
                name="cfdiFac"
                value={cfdiFac}
                onChange={handleInputChange}
              >
                <option disabled>Selecciona</option>
                {cfdi.map((cfdiFac) =>
                  persona === 1 ? (
                    cfdiFac.tipo === 1 || cfdiFac.tipo === 0 ? (
                      <option value={cfdiFac.clave}>
                        {cfdiFac.clave} {cfdiFac.value}
                      </option>
                    ) : (
                      <></>
                    )
                  ) : persona === 2 ? (
                    cfdiFac.tipo === 2 || cfdiFac.tipo === 0 ? (
                      <option value={cfdiFac.clave}>
                        {cfdiFac.clave} {cfdiFac.value}
                      </option>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                )}
              </StyledSelect>
              <br />
              <StyledLabel
                controlId="floatingcomentarioFactura"
                label="Comentarios y observaciones facturación"
              >
                <StyledInput
                  type="text"
                  placeholder="Comentarios y observaciones facturación"
                  value={obsFac}
                  name="obsFac"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={handleInputChange}
                />
              </StyledLabel>
            </>
          ) : null}

          <Row className="mb-4 pb-2" style={{ paddingLeft: "3%" }}>
            <InputStyled
              key="check-soyMedico"
              id="check-newsletter"
              type="checkbox"
              name="soyMedico"
              label="Soy médico y quiero generar órdenes de estudios"
              value={imDr}
              style={{ margin: "10px 0" }}
              checked={imDr}
              isdoctor={isDoctor ? 1 : 0}
              onChange={() => setImDr(!imDr)}
            />
          </Row>
          {imDr ? (
            <>
              <Row className="mb-4">
                {/* <HeaderSection> */}
                <StyledNav style={{ paddingLeft: "0" }}>
                  <Col
                    xs={10}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ paddingLeft: "0" }}
                  >
                    <TabTitle selected={true} $isDoctor={isDoctor}>
                      Datos médicos para generar órdenes de estudios
                    </TabTitle>
                  </Col>
                </StyledNav>
                <Row style={{ paddingLeft: "0" }}>
                  <TabIndicatorCol
                    xs={10}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ paddingLeft: "0" }}
                    selected={true}
                    $isDoctor={isDoctor}
                  />
                </Row>
                {/* </HeaderSection> */}
              </Row>
              {subtitle(" Datos médicos y dirección de consultorio")}
              <Row className="mb-4 mt-4 pb-2 pt-1">
                <StyledLabel
                  controlId="cedula_profesional"
                  label="Cédula profesional"
                >
                  <StyledInput
                    type="text"
                    placeholder="Cédula profesional"
                    value={cedula_profesional}
                    name="cedula_profesional"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className="mb-4 mt-4 pb-2 pt-1">
                <StyledLabel
                  controlId="universidad"
                  label="Institución educativa"
                >
                  <StyledInput
                    type="text"
                    placeholder="Institución educativa"
                    value={universidad}
                    name="universidad"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className="mb-4 pb-2">
                <StyledLabel controlId="especialidad" label="Especialidad">
                  <StyledSelect
                    name="especialidad"
                    value={especialidad}
                    onChange={(e) => {
                      handleInputChange(e);
                      console.log(e.target.value);
                    }}
                  >
                    <option disabled>Especialidad</option>
                    {especialidades.map((especialidad, index) => (
                      <option key={index} value={especialidad.value}>
                        {especialidad.value}
                      </option>
                    ))}
                  </StyledSelect>
                </StyledLabel>
              </Row>
              {formValues.especialidad === "OTRA" && (
                <Row className="mb-4 pb-2">
                  <StyledLabel
                    controlId="otra_especialidad"
                    label="Especialidad"
                  >
                    <StyledInput
                      type="text"
                      placeholder="Especialidad"
                      value={otra_especialidad}
                      name="otra_especialidad"
                      autoComplete="off"
                      list="autocompleteOff"
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                </Row>
              )}
              <Row className="mb-4 pb-2">
                <StyledLabel
                  controlId="cedula_especialidad"
                  label="Cédula de especialidad (opcional)"
                >
                  <StyledInput
                    type="text"
                    placeholder="Cédula de especialidad (opcional)"
                    value={cedula_especialidad}
                    name="cedula_especialidad"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className="mb-4 mt-4 pb-2 pt-1">
                <StyledLabel
                  controlId="universidadB"
                  label="Institución educativa"
                >
                  <StyledInput
                    type="text"
                    placeholder="Institución educativa"
                    value={universidadB}
                    name="universidadB"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className="mb-4 pb-2">
                <StyledLabel
                  controlId="num_consejo"
                  label="Número de consejo (opcional)"
                >
                  <StyledInput
                    type="text"
                    placeholder="Número de consejo (opcional)"
                    value={num_consejo}
                    name="num_consejo"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className="mb-4 pb-2">
                <StyledLabel controlId="street" label="Calle">
                  <StyledInput
                    type="text"
                    placeholder="Calle"
                    value={street}
                    name="street"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className="mb-4 pb-2">
                <StyledLabel controlId="next" label="No. exterior">
                  <StyledInput
                    type="text"
                    placeholder="No. exterior"
                    value={next}
                    name="next"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              {(addInnerNumber || nint.length > 0) && (
                <Row className="mb-4 pb-2">
                  <StyledLabel controlId="nint" label="No. interior">
                    <StyledInput
                      type="text"
                      placeholder="No. interior"
                      value={nint}
                      name="nint"
                      autoComplete="off"
                      list="autocompleteOff"
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                </Row>
              )}
              {!addInnerNumber &&
                !(nint.length > 0) &&
                addAnotherSomething("Añadir número interior")}
              <Row className="mb-4 pb2">
                <StyledLabel controlId="cp" label="Código postal">
                  <StyledInput
                    type="text"
                    placeholder="Código postal"
                    value={cp}
                    name="cp"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className="mb-4 pb2">
                <StyledLabel controlId="colonia" label="Colonia">
                  <StyledInput
                    type="text"
                    placeholder="Colonia"
                    value={colonia}
                    name="colonia"
                    autoComplete="off"
                    list="autocompleteOff"
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              {(addOfficeNumberOrName || officeNameOrNumber.length > 0) && (
                <Row className="mb-4 pb-2">
                  <StyledLabel
                    controlId="officeNameOrNumber"
                    label="Nombre o número de consultorio"
                  >
                    <StyledInput
                      type="text"
                      placeholder="Consultorio"
                      value={officeNameOrNumber}
                      name="officeNameOrNumber"
                      autoComplete="off"
                      list="autocompleteOff"
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                </Row>
              )}

              {/* {prevPicture !== "" && picture === "" &&
                <Button onClick={setPicture(prevPicture)}>
                  Cancelar
                </Button>
              } */}
              <Delimitador isDoctor={isDoctor} />

              {!addOfficeNumberOrName &&
                !(officeNameOrNumber.length > 0) &&
                addAnotherSomething("Añadir número o nombre del consultorio")}

              {/* <Row className='mb-4 pb-2'> */}
              {/* {phoneInputComp('phoneNumberConsultingRoom')} */}
              <Form.Label style={{ ...smallText, paddingLeft: "1.8%" }}>
                Teléfono del consultorio
              </Form.Label>
              <BorderForPhoneInput>
                <StyledPhoneInput
                  type="tel"
                  international
                  defaultCountry="MX"
                  placeholder="Teléfono del consultorio"
                  value={phoneNumberConsultingRoom}
                  name="phoneNumberConsultingRoom"
                  id="phoneNumberConsultingRoom"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={setPhoneConsultRoom}
                />
              </BorderForPhoneInput>
              {/* </Row> */}
              {!addExtensionNumber &&
                !(extensionNumber.length > 0) &&
                addAnotherSomething("Añadir número de extensión")}
              {(addExtensionNumber || extensionNumber.length > 0) && (
                <Row className="mb-4 pb-2">
                  <StyledLabel
                    controlId="extensionNumber"
                    label="Número de extensión"
                    style={{ width: "339px", paddingLeft: "1.5%" }}
                  >
                    <StyledInput
                      type="text"
                      placeholder="Número de extensión"
                      value={extensionNumber}
                      name="extensionNumber"
                      autoComplete="off"
                      list="autocompleteOff"
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                </Row>
              )}
              {/* <Row className='mb-4 pb-2'> */}
              {/* {phoneInputComp('phoneNumberEmergency')} */}
              <Form.Label style={{ ...smallText, paddingLeft: "1.8%" }}>
                Teléfono para urgencias
              </Form.Label>
              <BorderForPhoneInput>
                <StyledPhoneInput
                  type="tel"
                  international
                  defaultCountry="MX"
                  placeholder="Teléfono para urgencias"
                  value={phoneNumberEmergency}
                  name="phoneNumberEmergency"
                  id="phoneNumberEmergency"
                  autoComplete="off"
                  list="autocompleteOff"
                  onChange={setPhoneEmergency}
                />
              </BorderForPhoneInput>
              {/* </Row> */}
              <Row>
                <h5
                  style={{
                    fontSize: "16px",
                    marginTop: "22px",
                    marginBottom: "20px",
                  }}
                >
                  Redes
                </h5>
                <Col xs={12} md={6} lg={4}>
                  <StyledLabel controlId="facebook" label="Facebook">
                    <UilFacebookF className="input-icon" color="#AAA" />
                    <StyledInput
                      style={{ paddingLeft: "30px" }}
                      type="text"
                      placeholder="Facebook"
                      value={facebook}
                      name="facebook"
                      autoComplete="off"
                      list="autocompleteOff"
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                  {facebook !== undefined && facebook !== "" ? null : (
                    <p className="text-muted mt-1">
                      &nbsp;&nbsp;Nombre del perfil
                    </p>
                  )}
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <StyledLabel controlId="instagram" label="Instagram">
                    <UilInstagram className="input-icon" color="#AAA" />
                    <StyledInput
                      style={{ paddingLeft: "30px" }}
                      type="text"
                      placeholder="Instagram"
                      value={instagram}
                      name="instagram"
                      autoComplete="off"
                      list="autocompleteOff"
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                  {instagram !== undefined && instagram !== "" ? null : (
                    <p className="text-muted mt-1">&nbsp;&nbsp;@your_user</p>
                  )}
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <StyledLabel controlId="web" label="Página web">
                    <UilGlobe className="input-icon" color="#AAA" />
                    <StyledInput
                      style={{ paddingLeft: "30px" }}
                      type="text"
                      placeholder="Página web"
                      value={web}
                      name="web"
                      autoComplete="off"
                      list="autocompleteOff"
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                  {web !== undefined && web !== "" ? null : (
                    <p className="text-muted mt-1">&nbsp;&nbsp;mipagina.com</p>
                  )}
                </Col>
              </Row>
              {picture !== "" ? (
                <>
                  <Row className="mb-4 mt-5">
                    <h5 style={{ fontSize: "16px" }}>
                      Logo de la orden medica
                      {picture === user.profile.logo && (
                        <>
                          <br />
                          <br />
                          <a
                            href={`https://lpqsystem.com/getReceta?id=54806&dr=${uidProfile}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#4D99E5",
                              textDecoration: "underline",
                            }}
                          >
                            ¿Cómo se ve mi orden médica?
                          </a>
                        </>
                      )}
                    </h5>
                  </Row>
                  <Row
                    style={
                      isMobile
                        ? { justifyContent: "center" }
                        : { paddingLeft: "10%" }
                    }
                    className="mb-4"
                  >
                    <LogoDocContainer bg={picture} />
                  </Row>
                  <Row
                    style={
                      isMobile
                        ? { justifyContent: "center" }
                        : { paddingLeft: "8.8%" }
                    }
                    className="mb-3"
                  >
                    <Button
                      className="w-auto"
                      onClick={editInputFile}
                      style={{
                        color: "#4D99E5",
                        background: "#FFF",
                        border: "2px solid #4D99E5",
                      }}
                    >
                      Cambiar imagen
                    </Button>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="mb-4">
                    <h5 style={{ fontSize: "16px" }}>Agrega tu logo</h5>
                  </Row>
                  <Row
                    style={
                      isMobile
                        ? { justifyContent: "center" }
                        : { paddingLeft: "3%" }
                    }
                  >
                    <UploadImage onClick={handleInputFile} className="mb-4">
                      <input
                        accept="image/*"
                        type="file"
                        hidden
                        id="inputFile"
                        onChange={handleFileChange}
                        ref={myRef}
                      />
                      <UilCameraPlus
                        size={40}
                        color={isDoctor ? "#4D99E5" : "#00AD90"}
                      />
                    </UploadImage>
                  </Row>
                </>
              )}
            </>
          ) : null}
          {/* Campo de checkbox ofertas solo para paciente */}
          <Row style={{ paddingLeft: "3%" }} className="mb-2">
            {newsletterCheckBox}
          </Row>
          <Row
            className="mb-5"
            style={
              isMobile ? { justifyContent: "center" } : { paddingLeft: "3%" }
            }
          >
            <Button
              variant="primary"
              style={
                isDoctor
                  ? {
                      ...buttonStyle,
                      background:
                        "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)",
                      borderRadius: "10px",
                    }
                  : {
                      ...buttonStyle,
                      background:
                        "linear-gradient(79.06deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%)",
                      borderRadius: "10px",
                    }
              }
              onClick={handleUpdateProfileData}
            >
              <b>Guardar cambios</b>
            </Button>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Fotoperfil />
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};
