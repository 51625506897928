import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { CATEOGORIES, TEXT_BLACK } from '../colors';
import { CA_GRADIENT_BUTTON, GREEN_LAB } from '../../../helpers/colors';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { NewComment } from '../comments/newComment';
import { getLastPosts, getPost } from '../../../actions/blog';
import handleAlert from '../../../helpers/handleAlert';
import { timestampToSpanishDate } from '../../../helpers/hours';
import { extractPlainText } from '../../../helpers/any';
import { UimStar } from '@iconscout/react-unicons-monochrome';
import { UilArrowRight } from '@iconscout/react-unicons';
import { Comments } from '../comments/comments';
import { getUserInfo } from '../../../actions/user';

const StyledContainer = styled(Container)`
  #banner {
    width: 100%;
    height: 382px;
    background: ${({banner, color}) => banner ? `url('${banner}')` : color} no-repeat;
    background-size: cover;
    background-position: center;
  }
  #back-btn {
    position: absolute;
    top: 0px;
    left: 10px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
  }
  #bread-crumbs {
    text-align: center;
    color: ${TEXT_BLACK};
    font-size: 15px;
    font-weight: 400;
  }
  #date-cat {
    color: ${TEXT_BLACK};
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    span {
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.54px;
      background: ${({color}) => color};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  #writter {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      border-radius: 50%;
      margin: 0 10px 10px 0;
    }
  }
  @media only screen and (width <= 768px) {
    #hide-mb {
      display: none;
    }
  }
  
`;

const BlogDiv = styled.div`
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-image: ${CA_GRADIENT_BUTTON};
  border-image-slice: 1;
  padding: 24px;
  padding-bottom: 5px;
  h2 {
    color: var(--Negro, #474A4B);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
  }
  h3 {
    color: #00AD90;
    font-size: 19px;
    font-style: italic;
    font-weight: 500;
    line-height: 26px; /* 136.842% */
  }
  p {
    color: var(--Negro, #474A4B);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  span {
    font-style: italic;
  }
`;

const RecentDiv = styled.div`
  padding: 24px 30px 36px 20px;
  margin-top: 40px;
  border: 1px solid #DDDDDD;
  h2 {
    color: var(--Negro, #474A4B);
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
  }
`;

const CategoriesDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 30px 36px 20px;
  margin-top: 30px;
  h2 {
    color: var(--Negro, #474A4B);
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
  }
`;

const CatAnchor = styled.a`
  color: ${props => props.color};
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.54px;
  text-decoration-line: underline;
  margin-top: 15px;
`;

const StyledRecent = styled.div`
  width: 100%;
  position: relative;
  border-radius: 0px 0px 16px 0px;
  padding: 20px 0px;
  margin: 0px auto 0px;
  hr {
    width: 40%;
    position: absolute;
    left: 0;
    top: 0px;
    color: ${props=>props?.color ?? '#CCC'};
    opacity: 1;
    height: 2px;
  }
  #stars-div {
    position: absolute;
    top: -16px;
    right: 10px;
    svg {
      color: #30DF99;
    }
  }
  h1 {
    color: #474A4B;
    font-family: Faustina;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.27px;
    margin-top: -15px !important;
    margin-bottom: 5px;
    max-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #category {
    color: #A28EDC;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
  }
  #continue {
    transition: all 0.3s ease;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

const ContentP = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 8em; /* Adjust as needed */
  line-height: 1.5em; /* Adjust as needed */
`;

const ContentDiv = styled.div`
  p, li {
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  img {
    max-width: 100%;
  }
`;

export const Post = () => {
  const [postData, setPostData] = useState({});
  const [recentPosts, setRecentPosts] = useState([]);
  const [authorInfo, setAuthorInfo] = useState();
  const { postId } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const posts = await getLastPosts(2);
      setRecentPosts(posts);
    } catch (error) {
      // Handle error
      console.error('Error fetching posts without comments: ', error);
    }
  };

  useEffect(() => {
    getPost(postId)
      .then(data => {
        getUserInfo(data?.authorUID).then((res) => setAuthorInfo(res?.data));
        setPostData({...data, categoryInfo: CATEOGORIES?.find(e => e?.id === data?.category)});
      })
      .catch(err => handleAlert('error', 'Hubo un error al obtener la información del post', 'Hubo un problema :c'));
  }, [postId]);

  return (
    <StyledContainer color={postData?.categoryInfo?.color} banner={postData?.banner}>
      <Row>
        <Col xs={12} lg={9}>
          {/* Banner */}
          <div id='banner'/>

          {/* Back button */}
          <Container className='position-relative mt-2'>
            <p id='back-btn' onClick={()=> window.location.href = '/blog/categorias'}><UilArrowLeft color={GREEN_LAB}/> Regresar</p>
          </Container>

          {/* Category info */}
          <p id='bread-crumbs'>Blog / {postData?.categoryInfo?.name}</p>

          {/* Date info */}
          <p id='date-cat'>{timestampToSpanishDate(postData?.created) ?? 'Sin fecha'} <span> | {postData?.categoryInfo?.name}</span></p>

          {/* Title */}
          <h1>{postData?.title ?? ''}</h1>
          <hr />
          
          {/* Writter info */}
          <div id='writter'>
            <img src={authorInfo?.photoURL?.length >= 10 
              ? authorInfo?.photoURL
              : authorInfo?.profile?.logo?.length >= 10
                ? authorInfo?.profile?.logo
                : ''
            } alt="" />
            <p>Escrito por <span>{postData?.author}</span></p>
          </div>

          {/* Content */}
          <ContentDiv id='content' dangerouslySetInnerHTML={{ __html: postData?.content }} />

          {/* Comments */}
          {/* <Comments postId={postId}/> */}

          {/* New comment */}
          {/* <NewComment postId={postId}/> */}
        </Col>
        <Col xs={12} lg={3} id='hide-mb'>
          <BlogDiv>
            <h2>Labopat Blog</h2>
            <h3>#HacemosCiencia</h3>
            <p>Éste es un espacio más que Labopat crea para que <span>el conocimiento sobre temas de salud sea colectivo.</span>Éste es un espacio más que Labopat crea para que <span>el conocimiento sobre temas de salud sea colectivo.</span></p>
          </BlogDiv>
          <RecentDiv>
            <h2>Entradas recientes</h2>
            {recentPosts?.map((recent, index) => {
              const truncatedText = extractPlainText(recent?.content);
              const currentCat = CATEOGORIES.find(cat => cat?.id === recent?.category);
              return (
                <StyledRecent key={index} color={currentCat?.color}>
                  <hr />
                  <p>{currentCat?.name}</p>
                  <h1>{recent?.title}</h1>
                  <ContentP className='desc'>{truncatedText}</ContentP>
                  <div id='stars-div'>
                    {Array.from(new Array(Math.round(recent?.averageRate ?? 0))).map((_, i) => (
                      <UimStar key={i} size={30}/>
                    ))}
                  </div>
                  <div id='continue' onClick={() => history.push(`/post/${recent?.id ?? 0}`)}>Seguir leyendo <UilArrowRight/></div>
                </StyledRecent>
              )
            })}
          </RecentDiv>
          <CategoriesDiv>
            <h2>Explora por categoría</h2>
            {CATEOGORIES?.map((cat, index)=>
              <CatAnchor color={cat?.color} href={`/blog/${cat?.link}`} key={index}>{cat?.name}</CatAnchor>
            )}
          </CategoriesDiv>
        </Col>
      </Row>
    </StyledContainer>
  );
};
