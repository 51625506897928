import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import {
  UilAngleDown,
  UilAngleUp,
  UilLocationPinAlt,
  UilSearchPlus,
  UilSearchMinus,
  UilSchedule,
} from '@iconscout/react-unicons';
import Slider from 'react-slick';
import { CalendarModal } from './calendarModal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Loader } from '../general/spinner';
import { branches as branchesA } from '../../helpers/branches';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UIDsHome } from '../../helpers/cart';
const API = 'https://labopat-5792e.appspot.com/';

const StyledForDesktop = styled.div`
  max-width: 548px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-top: -45px;
    margin-bottom: 24px;
  }
`;

const RegularP = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const PostalInput = styled(Form.Control)`
  position: unset !important;
  width: 150px;
  height: 61px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  background: #f6f6f6 !important;
  border: none;
  border-radius: 0px 12px 12px 0px !important;
  height: 50px;
  margin-right: 16px;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
  }
`;

const PostalButton = styled(Button)`
  z-index: 0 !important;
  font-weight: 800;
  width: 100px;
  height: 48px;
  background: ${props => props.isDoctor ? '#4D99E5' : 'linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)'};
  border: 2px solid ${props => props.isDoctor ? '#4D99E5' : '#30DF99'};
  font-weight: 500;
  color: #fff;
  margin-left: 15px;
  font-size: 16px;
  transition: all 0.4s ease;
  border-radius: 10px !important;
  :hover {
    border: 2px solid ${props => props.isDoctor ? '#4D99E5' : '#30DF99'};
    transition: all 0.4s ease;
    background: #fff;
    color: ${props => props.isDoctor ? '#4D99E5' : '#30DF99'};
  }
  :disabled {
    border: none;
    background: #e2e2e2;
    color: #A6A6A6;
  }
  :focus {
    background: #00ad90;
  }
`;

const Green = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.isDoctor ? '#4D99E5' : '#00ad90'};
`;

const StyledSelect = styled(Form.Select)`
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #f6f6f6;
  border-radius: 12px;
  margin-top: 0;
  margin-bottom: 20px;
`;

const DateButton = styled.button`
  width: 85px;
  background: #fff;
  box-shadow: 0px 4px 4px #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e0ecec;
  > :nth-child(1) {
    color: #a6a6a6;
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 12px;
  }
  > :nth-child(2) {
    color: #000000;
    font-size: 24px;
    margin-bottom: 0px;
  }
  > :nth-child(3) {
    font-size: 16px;
    color: #000;
  }
`;

const StyledSlider = styled(Slider)`
  > .slick-dots li {
    display: none;
  }

  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

const DateCol = styled(Col)`
  padding: 8px;
  display: flex !important;
  justify-content: center;
  align-items: center;
`;

const holidays = [
  {
    day: 1,
    month: 1,
  },
  {
    day: 5,
    month: 2,
  },
  {
    day: 21,
    month: 3,
  },
  {
    day: 1,
    month: 5,
  },
  {
    day: 16,
    month: 9,
  },
  {
    day: 20,
    month: 11,
  },
  {
    day: 25,
    month: 12,
  },
];

const Nearest = styled.div`
  background: #dbf1ff;
  border-radius: 12px;
  padding: 12px 16px;
  > p {
    margin-bottom: 4px;
  }
  > :nth-child(1) {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #3b3b3b;
  }
  > :nth-child(2) {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #3b3b3b;
  }
  > :nth-child(3) {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    > a {
      color: #00ad90;
    }
  }
  > :nth-child(3):hover {
    text-decoration: underline;
    color: #3eb8b2;
  }
`;

const ButtonMenu = styled.button`
  width: 100%;
  text-align: left;
  color: black;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  border: none;
`;

const Day = styled.div`
  background: #dbf1ff;
  border-radius: 12px;
  padding: 12px 16px;
  > p {
    margin-bottom: 0px;
    > span {
      font-weight: 800 !important;
    }
  }
`;

const StyledPlaces = styled.div`
  > div .form-check {
    display: flex;
    align-items: center;
    color: ${props => props.isDoctor ? '#4D99E5' : '#00AC90'};
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
  }
  > div .form-check label {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #474A4B;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: ${props => props.isDoctor ? '#4D99E5' : '#00AC90'};
    border-color: ${props => props.isDoctor ? '#4D99E5' : '#00AC90'};
  }
  > div a {
    color: ${props => props.isDoctor ? '#4D99E5' : '#00AC90'};
  }
  > div a:hover {
    text-decoration: underline;
    color: #3eb8b2;
  }
  > div > div > div {
    position: relative;
    @media only screen and (max-width: 576px) {
      padding-bottom: 40px;
    }
  }
  .phone-link {
    color: ${props => props.isDoctor ? '#4D99E5' : '#00AC90'};
    font-weight: 700;
    padding-left: 20px;
    @media only screen and (max-width: 576px) {
      margin-top: 5px;
    }
    @media only screen and (min-width: 576px) {
      position: absolute;
      top: 3px;
      right: 20px;
      width: auto;
      display: flex;
    }
  }
`;

const AddCouponText = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  text-align: center;
  color: ${props => props.isDoctor ? '#4D99E5' : '#00AC90'};
  cursor: pointer;
  margin-top: 10px;
`;

const MyFloating = styled(FloatingLabel)`
  max-width: 358px;
  margin-left: auto;
  margin-right: auto;
  .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    padding-left: 16px;
    padding-top: 0.5em !important;
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }
`;

const GreenButton = styled.button`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin: 15px auto 24px auto;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const Message = styled.p`
  margin: 10px auto 25px;
  text-align: center;
  span {
    font-weight: 800;
    color: #00ac90;
  }
`;

const ChatButton = styled.a`
  width: 260px;
  height: 48px;
  border: 1.5px solid #00ad90;
  box-sizing: border-box;
  border-radius: 24px;
  background: #fff;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  color: #00ad90;
  margin-top: 10px;
  margin-bottom: 24px;
  transition: all 0.3s ease;
  padding-top: 11px;
  :hover {
    color: #fff;
    background: #00ad90;
  }
  @media only screen and (min-width: 768px) {
    width: 300px;
  }
`;

const NoBranches = styled.div`
  padding-top: 50px;
  padding-bottom: 35px;
  background: #dbf1ff;
  border-radius: 16px;
  margin-bottom: 20px;
`;

const StyledImg = styled.div`
  padding: 12px;
  position: absolute;
  top: ${props => props.photo ? "-60" : "-470"}px;
  right: -100px;
  z-index: 1;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
  transition: all ease .4;
  width: auto;
  img {
    width: 426px;
  }
  @media only screen and (max-width: 600px) {
    display: ${props => props.photo ? "block" : "none"};
    right: 15px;
    top: -20px;
    img {
      width: 100%;
    }
  }
  #plus-icon {
    width: 41px;
    height: 41px;
    background: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
    cursor: pointer;
  }
`;

const MiniImage = styled.div`
  cursor: pointer;
  @media only screen and (max-width: 576px) {
    width: auto;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  #mini-image {
    position: relative;
    width: 70px;
    height: 72px;
    background: #F6F6F6;
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #plus-icon {
    width: 41px;
    height: 41px;
    background: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 58px;
    height: 58px;
    border-radius: 10px;
  }
`;

const NoHours = styled.div`
  width: 100%;
  padding: 20px 30px;
  background: #dbf1ff;
  margin-bottom: 20px;
  border-radius: 20px;
  text-align: center;
  p {
    margin-bottom: 0;
  }
`;

export const StepTwo = (props) => {
  const [items, setItems] = useState(props.items);
  const [nutrigenetica, setNutrigenetica] = useState(false);
  const [molecular, setMolecular] = useState(false);
  const [patologia, setPatologia] = useState(false);
  const [clinicos, setClinicos] = useState(false);
  const [covid, setCovid] = useState(false);
  const [rayosx, setRayosx] = useState(false);
  const [torres, setTorres] = useState(false);
  const [postal, setPostal] = useState('');
  const [viewCupon, setViewCupon] = useState(false);
  const [cupon, setCupon] = useState('');
  const [cuponApplied, setCuponApplied] = useState(false);
  const [cuponMessage, setCuponMessage] = useState('');
  const uid = useSelector((state) => state.auth.uid);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const isDoctor = useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isSucursal = urlParams.get('sucursal');
  var today = new Date(Date.now());
  if (today.getDay() === 0) {
    today.setDate(today.getDate());
  }
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showCalendar, setShowCalendar] = useState(false);
  const dateArray = [];
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const [nearestPlace, setNearestPlace] = useState();
  const [showPlaces, setShowPlaces] = useState(true);
  const [hours, setHours] = useState([]);
  const [branches, setBranches] = useState(branchesA);
  const [loading, setLoading] = useState(false);
  const [isShown, setIsShown] = useState(["",""]);
  const [isShownPhoto, setIsShownPhoto] = useState(["",""]);
  const {width} = useWindowDimensions();
  const number = '+522212311313';

  // Check if today is holiday
  let hoyFestivo = holidays.some(
    (holiday) =>
      holiday.day === today.getDate() && holiday.month === today.getMonth() + 1
  );

  // Fill dates array with two weeks
  for (let index = 0; index < 15; index++) {
    var tempDate = new Date(today);
    tempDate.setDate(today.getDate() + index);
    if (index !== 0) {
      dateArray.push(tempDate);
    } else if (
      tempDate.getDay() <= 5 && //Entre semana
      !hoyFestivo && //No es festivo
      (tempDate.getHours() < 19 ||
        (tempDate.getHours() === 19 && tempDate.getMinutes() < 10)) //Antes de 14:10
    ) {
      dateArray.push(tempDate);
    } else if (
      hoyFestivo &&
      (tempDate.getHours() < 20 ||
        (tempDate.getHours() === 12 && tempDate.getMinutes() < 20)) //Antes de 12:20
    ) {
      dateArray.push(tempDate);
    } else if (
      tempDate.getDay() === 6 && //Sabado
      !hoyFestivo && //No es festivo
      (tempDate.getHours() < 18 ||
        (tempDate.getHours() === 18 && tempDate.getMinutes() < 40)) //Antes de 13:40
    ) {
      dateArray.push(tempDate);
    }
  }

  // Main info used in Checkout component
  const [place, setPlace] = useState(props.place);
  const [time, setTime] = useState(props.time ? props.time : '');
  const [selectedDate, setSelectedDate] = useState(props.date ? props.date : undefined);

  const forceShow = () => {
    if (showCalendar) {
      setShowCalendar(false);
      setTimeout(() => {
        setShowCalendar(true);
      }, 1);
    } else {
      setShowCalendar(true);
    }
  };

  const handleCalendar = (values) => {
    setSelectedDate(values);
  };

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // Update info when date or place change
  useEffect(()=>{
    // Reset time
    setTime('');
    // Check if is sunday and is available in the selected place
    if (selectedDate?.getDay() === 0 && !place?.sunday) {
      selectedDate?.setDate(selectedDate?.getDate() + 1);
    }
    // Get available hours
    fillScheduleRequest();
  },[selectedDate, place]);

  // To filter the branches where the studies are available
  const getAvailableBranches = async () => {
    setLoading(true);
    const itemsIds = [];
    items.forEach((item) => itemsIds.push(item.id));
    console.log('Studies IDs:' + itemsIds);
    await axios
      .post('https://labopat-5792e.appspot.com/sucursales', {
        studies: itemsIds,
      })
      .then((response) => {
        console.log('Branches from response:' + response.data.sucursales);
        const availableBranches = [];
        response.data?.sucursales?.forEach((sucursalId) => {
          if (branchesA.find((e) => e.idLpq === sucursalId) !== undefined) {
            availableBranches.push(
              branchesA.find((e) => e.idLpq === sucursalId)
            );
          }
        });
        if(UIDsHome.includes(uid) || clinicos || covid){
          availableBranches.push(
            {
              id: 11,
              idLpq: 22, // TODO: Ask for this ID
              name: 'Domicilio',
              address:'Pruebas LPQ',
              mapsId: '',
              mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
              coordinates: ['19.0267687', '-98.2311346'],
              photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FPalmas.png?alt=media&token=31dd89f7-55d3-42a1-b24e-9934839f0c4f',
              phone: '+52 221 231 13 13',
              email: 'pagos.sucursales.labopat@gmail.com'
          },
          )
        }
        setBranches(availableBranches);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAvailableBranches();
    items.forEach((item) => {
      if(item.categoria==4){
        setClinicos(true);
      }
      if(item.categoria==5){
        setCovid(true);
      }
      if(item.categoria==6 || item.categoria==9){
        setNutrigenetica(true);
      }
      if(item.categoria==7){
        setPatologia(true);
      }
      if(item.categoria==13){
        setMolecular(true);
      }
      if(item.categoria==15){
        setRayosx(true);
      }
      /*if(item.id==125 || item.id==126 
        || item.id==1001 || item.id==586 
        || item.id==773){
        setTorres(true);
      }*/
    });
  }, []);

  // Update main Checkout component every time the info change
  useEffect(() => {
    props.onStepTwo({
      place: place,
      date: selectedDate,
      time: time,
    });
  }, [place, selectedDate, time]);

  // Handle branches select
  const handleChange = (e) => {
    e.persist();
    setPlace(
      branches[
        branches.findIndex(
          (element) => element.id === parseInt(e.target.value, 10)
        )
      ]
    );
  };

  // Find nearest place to be selected by default
  const findNearestPlace = async (zipCode) => {
    let distance = 100000000;
    try {
      let request = axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=mexico+${zipCode}&key=AIzaSyD8p1E3vVMWsjaCpWxnqHQDb-2cktQSk4I`
      );
      let { data } = await request;
      const location = data.results[0].geometry.location;
      branches.map(async (branch) => {
        let response = await axios.get(
          API +
            `distance?place1x=${branch.coordinates[0]}&place1y=${branch.coordinates[1]}&place2x=${location.lat}&place2y=${location.lng}`
        );
        const tempDistance =
          response.data.data.rows[0].elements[0].distance.value;
        console.log(
          branch.name +
            ' Distance: ' +
            response.data.data.rows[0].elements[0].distance.value
        );
        if (tempDistance < distance) {
          distance = response.data.data.rows[0].elements[0].distance.value;
          setNearestPlace(branch);
          setPlace(branch);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Get available schedules for selected date and place
  const fillScheduleRequest = async () => {
    if (place && selectedDate) {
      var lugar = place.id;
      setLoading(true);
      console.log("hola");
      console.log(place.id);
      console.log(selectedDate?.getFullYear());
      console.log(selectedDate?.getMonth());
      console.log(selectedDate?.getDate());
      if(place.id==9){ //LOCAL 2O
      if(covid){
        lugar = 8;
      }
      if(clinicos){
        lugar = 10;
      }
      if(nutrigenetica){
        lugar = 20;
      }
      }else{ //OTRAS SUCURSALES
        lugar = place.id;
        if(nutrigenetica){
          lugar = 30;
        }
        if(place.id==3 && rayosx){
          lugar=40;
        }
        /*if(place.id==3 && torres){
          lugar=0;
        }*/
      }
      let response = await axios.get(
        `https://labopat-5792e.appspot.com/get-horitas?sucursal=${
          lugar
        }&day=${selectedDate?.getFullYear()}-${
          selectedDate?.getMonth() + 1
        }-${selectedDate?.getDate()}`
      );
      console.log(response);
      console.log(lugar);
      setHours(response.data.horas);
      setLoading(false);
    }
  };

  const checkTimeDifference = (hour) => {
    if (
      // If selectedDate is today
      selectedDate?.getDate() === today.getDate() &&
      selectedDate?.getMonth() === today.getMonth()
    ) {
      let tempHour = new Date(Date.now());
      tempHour.setHours(hour.substring(0, 2));
      tempHour.setMinutes(hour.substring(3, 5));
      if ((tempHour.getTime() - today.getTime()) / (1000 * 60) > 1) {
        // Check if the diference is higher than 30 minutes
        return <option value={hour}>{hour}</option>;
      }
    } else {
      return <option value={hour}>{hour}</option>;
    }
  };

  const applyCupon = async () => {
    var formData = new FormData();
    formData.append('uid', uid);
    formData.append('cupon', cupon);
    console.log('*****');

    items.forEach((element) => {
      console.log(element);
      console.log('---------');
      formData.append('products[]', element.id);
      formData.append('studiesPrice[]', element.oldPrice);
      formData.append('studies[]', element.id);
      //return;
      /*element.forEach((elem) => {
        
      });*/
    });
    let response = await axios.post(
      'https://lpqsystem.com/apply-cupon-test',
      formData
    );
    console.log('cupon :', response.data);
    if (response.data.ok && response.data.tipo !== -1) {
      setCuponApplied(true);
      if (response.data.tipo === 0) {
        setCuponMessage('Descuento de martes aplicado correctamente');
      } else if (response.data.tipo === 1) {
        setCuponMessage(
          'Descuento aplicado correctamente, canjeaste un cupón gratuito'
        );
      } else if (response.data.tipo === 2) {
        setCuponMessage(
          `Descuento aplicado correctamente, tienes disponible un saldo de ${response.data.saldoDisponible} en tu tarjeta de regalo`
        );
      } else if (response.data.tipo === 3) {
        setCuponMessage('Descuento aplicado correctamente');
      } else if (response.data.tipo === 4) {
        setCuponMessage(
          'El cupón ya no es valido, comunícate a la línea Labopat'
        );
      }

      props.applyCupon(
        cupon,
        response.data.paquetes,
        response.data.free,
        response.data.cuponID,
        response.data.cliente
      );
    } else {
      setCuponApplied(false);
      if (response.data?.tipo === 4) {
        setCuponMessage(
          'El cupón ya no es valido, comunícate a la línea Labopat'
        );
      } else {
        setCuponMessage(
          'Hubo un error al canjear tu cupón. (Si persiste el error comunícate a la línea Labopat)'
        );
      }
    }
  };

  useEffect(() => {
    if (props.discount !== 0 && !cuponMessage.includes('Ahorraste')) {
      setCuponMessage(
        `${cuponMessage}. Ahorraste <span>${currency(
          props.discount.toFixed(2)
        )} MXN</span>`
      );
    }
  }, [props.discount, props.subTotalWeb, props.subTotalSuc]);

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const someNotAvailableOnline = () => {
    return items.some((study)=> study.categoria === 10 || study.categoria === 8 || study.ventaOnline === 0 );
  }

  return (
    <StyledForDesktop>
      {loading ? <Loader show={loading} /> : null}
      <Title>Selecciona la sucursal de tu preferencia</Title>
      <p style={{ fontSize: '10px', color: '#A6A6A6' }}>
        Resumen de solicitud:
      </p>
      {items.map((item, id) => (
        <Row>
          <Col xs={7} sm={8} lg={90} style={{ marginTop: '5px', marginBottom: '10px' }}>
            <p
              style={{ display: 'inline' }}
              >
              {id + 1}.&nbsp; {item.title}
            </p>
          </Col>
          <Col xs={5} sm={4} lg={3}>
            <p
              style={{
                marginTop: '0px',
                marginBottom: '5px',
                fontWeight: '600',
                marginLeft: '5px',
                textAlign: 'right'
              }}
              >
              {cuponApplied || isSucursal
                ? currency(item?.oldPrice?.toFixed(2))
                : currency(item?.price?.toFixed(2) ? item?.price?.toFixed(2): item?.promo_price?.toFixed(2))}{' '}
              MXN
            </p>
          </Col>
        </Row>
      ))}
      {props.discount !== 0 && (
        <p
          style={{ textAlign: 'center', fontWeight: '600', marginTop: '10px' }}
        >
          Total:{' '}
          <span
            style={{
              fontWeight: props.discount > 0 ? '400' : '800',
              textDecoration: 'line-through',
            }}
          >
            {currency(
              (isSucursal ? props.subTotalSuc : props.subTotalWeb) +
                props.discount
            )}{' '}
            MXN
          </span>
        </p>
      )}
      <p style={{ textAlign: 'center', fontWeight: '600', marginTop: '10px' }}>
        {props.discount === 0 && 'Total: '}
        <span style={{ fontWeight: '800' }}>
          {currency(isSucursal ? props.subTotalSuc : props.subTotalWeb)} MXN
        </span>
      </p>
      {
        !idClienteLabopat && (
        <AddCouponText onClick={() => setViewCupon(true)} isDoctor={isDoctor}>
          Agregar cupón
        </AddCouponText>
        )
      }
      
      {cuponMessage !== '' && (
        <Message dangerouslySetInnerHTML={{ __html: cuponMessage }} />
      )}
      {viewCupon && (
        <MyFloating controlId='floatingName' label='Ingresa tu cupón'>
          <Form.Control
            type='text'
            placeholder='Ingresa tu cupón aquí'
            value={cupon}
            onChange={(e) => {
              setCupon(e?.target?.value?.toUpperCase());
            }}
          />
          <p
            style={{
              textAlign: 'center',
              color: isDoctor ? '#4D99E5': '#00AC90',
              marginTop: '10px',
              marginBottom: '0px',
            }}
          >
            Los descuentos son con base en el precio de sucursal
          </p>
          <div className='d-flex align-items-center'>
            <GreenButton
              disabled={cuponApplied || cupon.length === 0}
              onClick={() => applyCupon()}
            >
              Aplicar cupón
            </GreenButton>
          </div>
        </MyFloating>
      )}
      <hr />
      {molecular && (
      <NoBranches>
          <p
            style={{
              textAlign: 'center',
              fontSize: '18px',
              width: '80%',
              marginLeft: '10%',
            }}
          >
            Si tiene dudas con los estudios que va a solicitar, 
            contacta con nosotros para poder ayudarte y revisemos su orden de estudios.
            Indicarnos tambien informacion de su muestra.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ChatButton
              href={`https://wa.me/${number}?text=Tengo dudas con mis estudios y requiero verificarlos con mi orden medica
                ${items.map((study)=> ' '+ study.name)}`}
              target='_blank'
              rel='noreferrer noopener'
            >
              Contáctar
            </ChatButton>
          </div>
        </NoBranches>
      )}
      {patologia && (
      <NoBranches>
          <p
            style={{
              textAlign: 'center',
              fontSize: '18px',
              width: '80%',
              marginLeft: '10%',
            }}
          >
            Si tiene dudas con los estudios que va a solicitar, 
            contacta con nosotros para poder ayudarte y revisemos su orden de estudios.
            Indicarnos tambien informacion de su muestra.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ChatButton
              href={`https://wa.me/${number}?text=Tengo dudas con mis estudios y requiero verificarlos con mi orden medica
                ${items.map((study)=> ' '+ study.name)}`}
              target='_blank'
              rel='noreferrer noopener'
            >
              Contáctar
            </ChatButton>
          </div>
        </NoBranches>
      )}
      {branches.length === 0 ? (
        <NoBranches>
          <p
            style={{
              textAlign: 'center',
              fontSize: '18px',
              width: '80%',
              marginLeft: '10%',
            }}
          >
            {someNotAvailableOnline() 
              ? `Algunos de tus estudios no están disponibles para reservar en línea, ` 
              : 'Tus estudios no se pueden realizar en la misma sucursal, '
            }
            contacta con nosotros para poder ayudarte:
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ChatButton
              href={`https://wa.me/${number}?text=${someNotAvailableOnline() 
                  ? 'Algunos de los estudios que deseo solicitar no se pueden reservar en linea:' 
                  : 'Los estudios que quiero solicitar no se pueden realizar en la misma sucursal:'
                }
                ${items.map((study)=> ' '+ study.name)}`}
              target='_blank'
              rel='noreferrer noopener'
            >
              Contáctar
            </ChatButton>
          </div>
        </NoBranches>
      ) : (
        <>
          <ButtonMenu
            variant='light'
            onClick={() => setShowPlaces(!showPlaces)}
          >
            <Row style={{ marginTop: '10px' }}>
              <Col sm={10} xs={10} style={{ paddingLeft: '5px' }}>
                <p style={{ fontSize: '16px' }}> Ver sucursales disponibles</p>
              </Col>
              <Col sm={2} xs={2}>
                {showPlaces ? (
                  <UilAngleUp size='32' color='#00AD90' />
                ) : (
                  <UilAngleDown size='32' color='#00AD90' />
                )}
              </Col>
            </Row>
          </ButtonMenu>
          {showPlaces && (
            <StyledPlaces
              isDoctor={isDoctor}
            >
              {branches.map((branch) => (
                <>
                  <Row>
                    <Col xs={12} sm={10}>
                      <Form.Group controlId={`place${branch.id}`}>
                        <Form.Check
                          value={branch.id}
                          type='radio'
                          aria-label='radio 3'
                          label={branch.name}
                          onChange={handleChange}
                          checked={place?.name === branch.name}
                        />
                        <p
                          onClick={() => {
                            setPlace(
                              branches[
                                branches.findIndex(
                                  (element) => element.id === branch.id
                                )
                              ]
                            );
                          }}
                          style={{
                            color: '#717171',
                            fontSize: '14px',
                            fontFamily:'400',
                            marginBottom: '0px',
                            marginLeft: '20px',
                            cursor: 'pointer',
                          }}
                        >
                          {branch.address}
                        </p>
                        <Row>
                          <a className="phone-link" href={`tel:${branch.phone}`}>{branch.phone}</a>
                          <Col xs={12} sm={6} md={8} style={{position: 'relative', paddingLeft: '20px'}}>
                            <p style={{marginBottom: '0px', marginTop: '5px'}}>
                              <a
                                href={branch.mapURL}
                                target='_blank'
                                rel='noopener noreferrer'
                                    onMouseEnter={() => setIsShown([branch.name, 0])}
                                    onMouseLeave={() => setIsShown(["",""])}
                              >
                                Abrir dirección en google maps
                              </a>
                            </p>
                            {(branch.image && isShown[0] === branch.name && isShown[1] === 0) &&
                              <StyledImg>
                                <img src={branch.image} alt="ubicacion sucursal"/>
                              </StyledImg>
                            }
                          </Col>
                          <Col xs={12} sm={6} md={4} style={{position: 'relative', paddingLeft: '20px'}}>
                            <p style={{marginTop: '5px', marginBottom: '0px'}}>
                              <a
                                href={`https://www.waze.com/ul?ll=${branch.coordinates[0]},${branch.coordinates[1]}&navigate=yes&zoom=17`}
                                target='_blank'
                                rel='noopener noreferrer'
                                onMouseEnter={() => setIsShown([branch.name,1])}
                                onMouseLeave={() => setIsShown(["",""])}
                              >
                                ¿Cómo llegar?
                              </a>
                            </p>
                            {(branch.image && isShown[0] === branch.name && isShown[1] === 1) &&
                              <StyledImg style={{right: '5px'}}>
                                <img src={branch.image} alt="ubicacion sucursal"/>
                              </StyledImg>
                            }
                            {(branch.photo && isShownPhoto[0] === branch.name && isShownPhoto[1] === 1) &&
                              <StyledImg photo={true}>
                                <img src={branch.photo} alt="ubicacion sucursal"/>
                                <div id="plus-icon" onClick={()=>setIsShownPhoto(["",""])}>
                                  <UilSearchMinus size={28} color={isDoctor ? "#4D99E5" : "#00AC90"}/>
                                </div>
                              </StyledImg>
                            }
                          </Col>
                          {width < 585 &&  
                            <MiniImage onClick={()=> setIsShownPhoto([branch.name,1])}>
                              <div id='mini-image'>
                                <img alt='foto sucursal' src={branch.photo}/>
                                <div id="plus-icon">
                                  <UilSearchPlus size={28} color={ isDoctor ? "#4D99E5" : "#00AC90"}/>
                                </div>
                              </div>
                            </MiniImage>
                          }
                        </Row>
                      </Form.Group>
                    </Col>
                    {width >= 585 &&
                      <Col sm={2}>
                        <MiniImage onClick={()=> setIsShownPhoto([branch.name,1])} style={{marginTop: '10px'}}>
                          <div id='mini-image'>
                            <img alt='foto sucursal' src={branch.photo}/>
                            <div id="plus-icon">
                              <UilSearchPlus size={28} color={isDoctor ? "#4D99E5" : "#00AC90"}/>
                            </div>
                          </div>
                        </MiniImage>
                        <p 
                          style={{
                            color: isDoctor ? '#4D99E5' : '#00AC90',
                            fontSize: '16px',
                            lineHeight: '24px',
                            marginTop: '8px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }} 
                          onClick={()=> setIsShownPhoto([branch.name,1])}
                        >Ver foto</p>
                      </Col>
                    }
                  </Row>
                  <hr />
                </>
              ))}
            </StyledPlaces>
          )}
        </>
      )}
      <p style={{ fontSize: '16px' }}>
        {' '}
        Elige un día para realizar el estudio:
      </p>

      <Green
        style={{ marginTop: '4px', pointer: 'cursor' }}
        isDoctor={isDoctor}
        onClick={() => {
          forceShow();
        }}
      >
        <UilSchedule /> Elegir fecha del calendario
      </Green>

      <StyledSlider
        dots={true}
        infinite={true}
        slidesToShow={screenWidth > 992 ? 5 : screenWidth > 576 ? 4 : 3}
        slidesToScroll={
          screenWidth > 1199
            ? 4
            : screenWidth > 991
            ? 3
            : screenWidth > 767
            ? 2
            : 3
        }
        autoplay={false}
        autoplaySpeed={4000}
        cssEase={'linear'}
        speed={600}
      >
        {dateArray.map((date, id) => {
          var selected =
            String(selectedDate?.getDate()).padStart(2, '0') ===
            String(date.getDate()).padStart(2, '0');
          return place?.sunday || date.getDay() !== 0 ? (
            <DateCol
              xs={4}
              style={{ border: selected ? '2px solid #000' : 'none' }}
            >
              <DateButton
                style={{
                  background: selected ? isDoctor ? '#4D99E5' : '#00AD90' : '#fff',
                }}
                onClick={() => {
                  setSelectedDate(date);
                }}
              >
                <p style={{ color: selected ? '#F1F6F6' : '#A6A6A6' }}>
                  {days[date.getDay()].substring(0, 3)}
                </p>
                <p style={{ color: selected ? '#F1F6F6' : '#000' }}>
                  {String(date.getDate()).padStart(2, '0')}
                </p>
                <p style={{ color: selected ? '#F1F6F6' : '#000' }}>
                  {months[date.getMonth()].substring(0, 3)}
                </p>
              </DateButton>
            </DateCol>
          ) : null;
        })}
      </StyledSlider>
      <br />
      <CalendarModal
        show={showCalendar}
        onHide={() => {
          setShowCalendar(false);
        }}
        onSelectDate={handleCalendar}
        date={selectedDate}
      />

      {hours?.length > 0 ? 
        <StyledSelect
          onChange={(e) => setTime(e.target.value)}
          defaultValue='Horario'
          style={{ marginTop: '26px', cursor: 'pointer' }}
          value={time}
          disabled={!place || !selectedDate}
        >
          <option disabled value=''>
            Selecciona un horario
          </option>
          {
            hours.map((hour) => checkTimeDifference(hour)) // Returns the available options
          }
        </StyledSelect>
      :
        <NoHours>
          <p>{selectedDate && place 
            ? 'No hay horarios disponibles para la fecha y sucursal seleccionadas' 
            : 'Selecciona una fecha y sucursal para ver los horarios disponibles'
          }</p>
        </NoHours>
      }

      <Day>
        <p>
          <span>Sucursal: </span>
          {place?.name}
        </p>
        <p>
          <span>Día seleccionado: </span>
          {selectedDate ? 
            `${days[selectedDate?.getDay()]}${' '}
            ${String(selectedDate?.getDate()).padStart(2, '0')} de${' '}
            ${months[selectedDate?.getMonth()]} del ${selectedDate?.getFullYear()}`
          :
          ''}
        </p>
        <p>
          <span>Horario: </span> {time}
        </p>
      </Day>
    </StyledForDesktop>
  );
};
