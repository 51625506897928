import {
  Container,
  FloatingLabel,
  Form,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import {
  UilSearchAlt,
  UilTimesCircle,
  UilClipboardAlt,
  UilPlusCircle,
  UilTrashAlt,
  UilTimes
} from "@iconscout/react-unicons";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import axios from "axios";
import { Loader } from "../general/spinner";
import styled from "styled-components";
import { setPatientInfo } from "../../actions/patientInfo";

const StyledTable = styled(Table)`
  th {
    border-color: ${(props) =>
      props.isdoctor === 1 ? "#4F90BA " : "#4F90BA"}!important;
  }
  tr {
    border-color: ${(props) =>
      props.isdoctor === 1
        ? "rgba(79, 144, 186, 0.3)"
        : "rgba(79, 144, 186, 0.3)"};
  }
`;

const SearchInput = styled(Form.Control)`
  background: 25% center rgb(225 240 254) !important;
  border: none;
  border-radius: 10px !important;
  height: 50px;
  padding-left: 40px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
`;

const MyFloating = styled(FloatingLabel)`
  margin-bottom: 12px;
  width: 100%;
  .form-control {
    border: none;
    background: #4d99e5;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
    max-height: 45px;
    padding: 0 0 0 40px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -10em -10em;
    border-radius: 8px;
    padding: 10px 0 10px 40px;
    height: auto;
    font-family: "IBM Plex Sans";
  }
  :active,
  :focus-within {
    label {
      background-color: transparent;
      margin-top: 5px;
      padding: 0 0 0 40px;
    }
  }
`;

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 57px;
  color: #474a4b;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const NormalText = styled.p`
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 26.73px;
  color: #45494a;
  text-align: left;
  margin: 0;
`;

const NormalTextB = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #3b3b3b;
`;

const SearchButton = styled(Button)`
  font-family: "IBM Plex Sans";
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  line-height: 23.76px;
  text-align: center;
  margin: 0;
  max-width: 200px;
  width: 100%;
  background: #4d99e5;
  border: 2px solid #fff;
  max-height: 45px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition: all 0.4s ease;
  border-radius: 10px;
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: #fff;
  }
  @media only screen and (max-width: 576px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const EmptyPatients = styled.div`
  height: 50vh;
`;

const EmptyIconContainer = styled.div`
  margin: 40px auto 24px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  left: 110px;
  top: 112px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyPatientsText = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #45494a;
  margin-bottom: 40px;
`;

const TableHeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #45494a;
`;

const CreateOrder = styled(Button)`
  text-align: center;
  font-weight: 800;
  border: none;
  font-size: 15px;
  line-height: 22px;
  background: transparent;
  color: #4d99e5;
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: transparent;
  }
`;

const DeleteButton = styled(Button)`
  text-align: center;
  font-weight: 800;
  border: none;
  font-size: 15px;
  line-height: 22px;
  background: transparent;
  color: #C4C4C4;
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: transparent;
  }
`;

const StyledModal = styled(Modal)`
  .modal-header {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #4f90ba;
  }
  .btn-close {
    left: 93%;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-title {
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    margin-right: auto;
    color: #4f90ba;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    background: #4f90ba;
    border: none;
  }
  .foot-btn {
    max-width: 368px;
    width: 90%;
    margin: 0 auto;
    border: 2px solid #4f90ba;
    border-radius: 18px;
  }
  .row {
    width: 100%;
  }
  span {
    font-weight: 800;
  }
`;

export const Patients = ({setShowCart}) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const isDoctor = useSelector((state) => state.user?.data?.data?.profile?.type) === 1;

  // Check if is doctor
  useEffect(() => {
    if (!isDoctor) {
      window.location.href = "/";
    }
  }, []);

  // Orders
  const uid = useSelector((state) => state?.auth?.uid);
  const [patients, setPatients] = useState([]);

  const [patient, setPatient] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const editing = false; // Change to state?
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [modalPatient, setModalPatient] = useState();

  useEffect(() => {
    // Search patients
  }, [search]);

  useEffect(() => {
    dd();
  }, []);

  const dd = async () => {
    try {
      //setLoading(true);
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/mis-pacientes?user=${uid}`
      );
      setPatients(requestPending.data);
      setFilteredPatients(requestPending.data);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.log(error);
    }
  };

  const ded = async (patient) => {
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/detalle-solicitud-paciente?id=${patient}`
      );
      setPatient(requestPending.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deletePatient = async () => {
    setLoading(true);
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/mis-pacientes?user=${uid}&id=${patient?.solicitud[0]?.solicitud?.id}`
      );
      console.log(requestPending.data);
      setLoading(false);
      setPatient([]);
      setPatients(requestPending.data);
      setFilteredPatients(requestPending.data);
      setShowDeleteModal(false);
      history.push("/pacientes");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const filterPatients = () => {
    if (search !== "") {
      setFilteredPatients(
        patients
          .map((patient) => {
            if (
              `${patient?.nombre?.toLowerCase() ?? ""} ${
                patient.app?.toLowerCase() ?? ""
              } ${patient.apm?.toLowerCase() ?? ""}`.includes(
                search?.toLowerCase()
              ) ||
              patient?.emailContacto
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) ||
              patient?.fnacimiento?.includes(search?.toLowerCase()) ||
              patient?.telContacto?.includes(search?.toLowerCase())
            ) {
              return patient;
            } else {
              return undefined;
            }
          })
          .filter((e) => e !== undefined)
      );
    }
  };

  const resetFilters = () => {
    setSearch("");
    setFilteredPatients(patients);
  };

  const handleClick = (patient) => {
    setModalPatient(patient);
    setShowOrderModal(true);
  }

  const handleClose = () => {
    setModalPatient(undefined);
    setShowOrderModal(false);
  }

  const createOrder = (way) => {
    // way = 0 is for cart
    // way = 1 is for packages
    const tempPatientInfo = {
      id: modalPatient?.id,
      name: modalPatient?.nombre ?? "",
      fathersLastname: modalPatient.app ?? "",
      mothersLastname: modalPatient.apm ?? "",
      email: modalPatient?.emailContacto ?? "",
      phone:
        modalPatient?.telContacto &&
        modalPatient?.telContacto !== "undefined"
          ? modalPatient?.telContacto
          : "+52",
      fnacimiento: modalPatient?.fnacimiento ?? "",
      gender: modalPatient?.genero ?? "",
    }
    localStorage.setItem("patientInfo", JSON.stringify(tempPatientInfo));
    dispatch(setPatientInfo(tempPatientInfo));
    if(way === 1){
      history.push('mis-paquetes?forPatient=1');
    }else{
      setShowCart();
      setShowOrderModal(false);
    }
  }

  return (
    <Container
      style={{ minHeight: "100vh" }}
      className={width < 768 ? "mt-5 pt-4" : ""}
    >
      <HelmetTitle title="Pacientes" name="description" content="content" />
      <Row className="mb-5">
        <HeaderSection>
          <HeaderTitle>Expediente de pacientes</HeaderTitle>
        </HeaderSection>
      </Row>
      <Row className="mb-4">
        <NormalText>
          Revisa el expediente médico y consulta los resultados de sus estudios
        </NormalText>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <div style={{ position: "relative" }}>
            <UilSearchAlt
              style={{
                zIndex: 50,
                color: "#A6A6A6",
                position: "absolute",
                left: "10px",
                top: "10px",
              }}
            />
            {search !== "" && (
              <UilTimesCircle
                style={{
                  zIndex: 50,
                  color: "#A6A6A6",
                  position: "absolute",
                  right: "10px",
                  top: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setSearch("")}
              />
            )}
            <MyFloating label="Buscar en el listado de pacientes">
              <SearchInput
                id="search-input"
                autocomplete="off"
                mainColor={"#4F90BA"}
                type="text"
                placeholder="Buscar en el listado de pacientes"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autofocus
              />
            </MyFloating>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={3}
          className="d-flex align-items-center justify-content-center"
        >
          <SearchButton
            style={{ marginTop: "-10px" }}
            onClick={() => filterPatients()}
          >
            Continuar
          </SearchButton>
        </Col>
        {patients?.length !== filteredPatients?.length && (
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex align-items-center justify-content-center"
          >
            <SearchButton
              style={{
                color: "#4F90BA !important",
                background: "#FFFFFF !important",
              }}
              onClick={() => resetFilters()}
            >
              Ver todos
            </SearchButton>
          </Col>
        )}
      </Row>
      <Row className="justify-content-center mb-5">
        {patients.length > 0 ? (
          <StyledTable responsive hover striped>
            <thead>
              <tr>
                <th>
                  <TableHeaderTitle>Paciente</TableHeaderTitle>
                </th>
                <th>
                  <TableHeaderTitle>Correo</TableHeaderTitle>
                </th>
                <th>
                  <TableHeaderTitle>Teléfono</TableHeaderTitle>
                </th>
                <th>
                  <TableHeaderTitle>F.nacimiento</TableHeaderTitle>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredPatients.length > 0 &&
                filteredPatients?.map((patient, index) => (
                  <tr>
                    <td
                      className="pt-4 pb-4"
                      onClick={() => history.push(`/paciente/${patient.id}`)}
                    >
                      <NormalTextB style={{ textDecoration: "underline" }}>
                        {patient.nombre} {patient.app} {patient.apm}
                      </NormalTextB>
                    </td>
                    <td
                      className="pt-4 pb-4"
                      onClick={() => history.push(`/paciente/${patient.id}`)}
                    >
                      <NormalTextB>{patient.emailContacto}</NormalTextB>
                    </td>
                    <td
                      className="pt-4 pb-4"
                      onClick={() => history.push(`/paciente/${patient.id}`)}
                    >
                      <NormalTextB>{patient.telContacto}</NormalTextB>
                    </td>
                    <td
                      className="pt-4 pb-4"
                      onClick={() => history.push(`/paciente/${patient.id}`)}
                      f
                    >
                      <NormalTextB>{patient.fnacimiento}</NormalTextB>
                    </td>
                    <td>
                      <CreateOrder onClick={()=>handleClick(patient)}>
                        <UilPlusCircle />
                        <br />
                        Crear orden
                      </CreateOrder>
                      {!editing && (
                        <DeleteButton onClick={() => {
                          setShowDeleteModal(true);
                          ded(`${patient.id}`);
                        }}>
                          <UilTrashAlt />
                          <br /> &nbsp;&nbsp;Eliminar
                        </DeleteButton>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </StyledTable>
        ) : (
          <EmptyPatients>
            <EmptyIconContainer>
              <UilClipboardAlt color="#A6A6A6" size={100} />
            </EmptyIconContainer>
            <EmptyPatientsText>Aún no tienes pacientes</EmptyPatientsText>
          </EmptyPatients>
        )}
      </Row>
      {loading ? <Loader show={loading} /> : null}
      <StyledModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Paciente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Estás seguro de eliminar al paciente: "
            {patient?.solicitud
              ? `${patient?.solicitud[0]?.solicitud?.nombre} ${patient?.solicitud[0]?.solicitud?.app} ${patient?.solicitud[0]?.solicitud?.apm}`
              : ""}
            " del Expediente de pacientes?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Button
              className="foot-btn"
              variant="primary"
              onClick={() => deletePatient()}
            >
              Eliminar
            </Button>
          </Row>
          <Row>
            <Button
              style={{
                color: "#4F90BA",
                background: "none",
                marginTop: "15px",
              }}
              className="foot-btn"
              variant="light"
              onClick={() => setShowDeleteModal(false)}
            >
              Regresar
            </Button>
          </Row>
        </Modal.Footer>
      </StyledModal>
      <StyledModal show={showOrderModal} onHide={() => handleClose()}>
        <div onClick={() => handleClose()} style={{marginBottom: '-20px'}}>
          <UilTimes color='#fff' style={{ cursor: 'pointer' }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>Crear orden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Con que estudios deseas crear la orden?
          </p>
          <Row>
            <Col xs={12} md={6}>
              <SearchButton onClick={()=>createOrder(0)}>
                Carrito
              </SearchButton>
            </Col>
            <Col xs={12} md={6}>
              <SearchButton onClick={()=>createOrder(1)}>
                Paquetes
              </SearchButton>
            </Col>
          </Row>
        </Modal.Body>
      </StyledModal>
      {/*<Row className='justify-content-center mb-5'>
        <AddPatientButon>
          <UilPlusCircle color='#4F90BA' size='24' style={{marginRight: '3%'}} className='icon' />
          Agregar paciente
        </AddPatientButon>
        </Row> */}
    </Container>
  );
};
