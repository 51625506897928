import { 
  UilEstate,
  UilSearchAlt,
  UilUser,
  UilUsersAlt,
  UilClipboardAlt,
  UilFilePlusAlt, 
  UilFileMedicalAlt,
  UilMonitorHeartRate,
} from '@iconscout/react-unicons';

export const Sections = {
  docSections: [
    {
      name: 'Inicio',
      icon: <UilEstate/>,
      route: '/dashboard'
    },
    {
      name: 'Mi perfil',
      icon: <UilUser/>,
      route: '/datos-personales'
    },
    {
      name: 'Buscar estudios',
      icon: <UilSearchAlt/>,
      route: 'search' 
    },
    {
        name: 'Estudios',
        icon: <UilClipboardAlt/>
    },
    {
      name: 'Mis resultados',
      icon: <UilMonitorHeartRate/>,
      route: '/resultados'
    },
  ],
  docPacientes: [
    {
      name: 'Paquetes',
      icon: <UilFilePlusAlt/>,
      route: '/mis-paquetes'
    },
    {
      name: 'Mis pacientes',
      icon: <UilUsersAlt/>,
      route: '/pacientes' 
    },
  ],
  userSections: [
    {
      name: 'Inicio',
      icon: <UilEstate/>,
      route: '/dashboard'
    },
    {
      name: 'Mi perfil',
      icon: <UilUser/>,
      route: '/datos-personales'
      },
    // {
    //   name: 'Resultados',
    //   icon: <UilMonitorHeartRate/>,
    //   route: '/resultados'
    // },
    {
      name: 'Estudios',
      icon: <UilClipboardAlt/>
    },
    {
      name: 'Mi historial médico',
      icon: <UilFileMedicalAlt/>,
      route: '/historial-medico'
    }
  ],
  homeSections: [
    {
      name: 'COVID-19',
      route: '/covid-19'
    },
    {
      name: 'Sucursales',
      route: '/sucursales'
    },
    {
      name: 'Médicos',
      route: '/medicos'
    },
  ],
  studiesAndServices: [
    {
      name: 'COVID-19',
      route: '/covid-19'
    },
    {
      name: 'Toma de biopsia',
      route: '/toma-biopsia'
    },
    {
      name: 'Nutrición',
      route: '/nutricion'
    },
    {
      name: 'Laboratorio clínico',
      route: '/laboratorio-clinico'
    },
    {
      name: 'Servicios genéticos',
      route: '/servicios-geneticos'
    },
    {
      name: 'Patología quirúrgica',
      route: '/patologia-quirurgica'
    },
    {
      name: 'Ultrasonido',
      route: '/ultrasonido'
    },
    {
      name: 'Biología molecular',
      route: '/molecular'
    },
    {
      name: 'Rayos X',
      route: '/rayosx'
    },

  ]
}