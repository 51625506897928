import Rosendo from '../assets/branches/Rosendo.png';
import Torres from '../assets/branches/Torres.png';
import Zavaleta from '../assets/branches/Zavaleta.png';

export const branches = [
    // {
    //     id: 4,
    //     idLpq: 26,
    //     name: 'Palmas Plaza Drive Thru',
    //     address:
    //         'Estacionamiento de Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
    //     mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
    //     mapsId: '',
    //     coordinates: ['19.0267687', '-98.2311346'],
    //     photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FPalmas.png?alt=media&token=31dd89f7-55d3-42a1-b24e-9934839f0c4f',
    //     phone: '+52 221 231 13 13',
    //     email: 'pagos.sucursales.labopat@gmail.com'
    // },
    {
        id: 9,
        idLpq: 5,
        name: 'Palmas Plaza Local 20',
        sunday: false,
        address:
            'Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
        mapsId: '',
        mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
        coordinates: ['19.0267687', '-98.2311346'],
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FPalmas.png?alt=media&token=31dd89f7-55d3-42a1-b24e-9934839f0c4f',
        phone: '+52 221 231 13 13',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/palmas_plaza',
        link: '/palmas_plaza'
    },
    {
        id: 3,
        idLpq: 13,
        name: 'Torres Médicas',
        address:
            'Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821',
        mapURL: 'https://goo.gl/maps/GKRKLQSnVXQPgt8o7',
        mapsId: '',
        coordinates: ['19.02051237624928', '-98.26456209191247'],
        image: Torres,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FTorres%20(1).png?alt=media&token=e085b667-2947-49c2-9262-95a867d8566b',
        //phone: '+52 222 970 07 32',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/torres_medicas',
        link:'/torres_medica'

    },
    {
        id: 7,
        idLpq: 21,
        name: 'La Paz',
        address:
            'Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.',
        mapURL: 'https://goo.gl/maps/nfcNL45qi89A5Lhw7',
        mapsId: '',
        coordinates: ['19.0522198', '-98.2362645'],
        image: Rosendo,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FLaPaz.png?alt=media&token=6b1091dc-a248-4751-b02b-022566293d75',
        //phone: '+52 222 972 26 62',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/lapaz',
        link: '/pabellon_r_m'
    },
        // {
        //   id: 2,
        //   idLpq: 11,
        //   name: 'Elementa',
        //   address:
        //     'Torre Elementa. Blvd. América 308, local 3; Lomas de Angelópolis, 72228 San Andrés Cholula, Pue.',
        //   mapURL: 'https://goo.gl/maps/wPfV3oCtYEcmmgxVA',
        //   mapsId: '',
        //   coordinates: ['18.9976678', '-98.2818113'],
        //   image: Elementa,
        //   photo: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FElementa.png?alt=media&token=6651d5ef-d952-4d39-9257-b21274d27965",
        //   phone: "+52 222 214 54 98",
        // },
    {
        id: 6,
        idLpq: 20,
        name: 'Bosques de Zavaleta',
        address:
            'Plaza Bosques De Zavaleta, Calz Zavaleta 313-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.',
        mapURL: 'https://goo.gl/maps/S7DapoFhy9F8zgNM9',
        mapsId: '',
        coordinates: ['19.066824', '-98.2503983'],
        image: Zavaleta,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FZavaleta.png?alt=media&token=ce00a37a-55e7-4d12-a104-e2ad00496875',
        //phone: '+52 222 976 76 62',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/bosques_zavaleta',
        link: '/bosques_zavaleta'
    },
    /*{
        id: 7,
        idLpq: 30, // TODO: Ask for this ID
        name: 'Aguascalientes',
        address:
            'Blvd. Luis Donaldo Colosio Murrieta 208, Lomas del Campestre II, 20119 Aguascalientes, Ags.',
        mapURL: 'https://goo.gl/maps/RVcPAV9ob4rRdfiG8',
        mapsId: '',
        coordinates: ['21.9244033', '-102.3156948'],
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FAguascalientes.png?alt=media&token=aeb384b0-17a2-478d-b73b-2cca968933a0',
        phone: '+52 449 710 2251',
        email: 'pagos.sucursales.labopat@gmail.com'
    },*/
];