import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { UilDownloadAlt } from '@iconscout/react-unicons';
// import { useLocation } from 'react-router-dom';

const StyledTable = styled(Table)`
  th {
    border-color: ${props => props.isdoctor === 1 ? '#4F90BA ' : '#00AC90'}!important;
  }
  tr {
    border-color: ${props => props.isdoctor === 1 ? 'rgba(79, 144, 186, 0.3)' : '#C1D9D8'};
  }
`;

const TableHeaderTitle = styled.p`
  margin: 0;
	font-family: 'IBM Plex Sans';
	font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #45494A;
`;

const NormalText = styled.p`
  margin: 0;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #3B3B3B;
`;

const DownloadText = styled.a`
	font-family: 'IBM Plex Sans';
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
	text-decoration-line: underline;
  color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
  text-align: left;
  margin: 0;
  width: auto;
  :hover {
    color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
    text-decoration: none;
  }
`;

const DownloadBtn = styled.img`
  width: auto;
	max-width: 18px;
	max-height: 20px;
`;

export const TableResults = (props) => {
  // const location = useLocation();
  return(
    <div>
      <StyledTable responsive='md' style={{maxWidth: '872px'}} isdoctor={props.isDoctor ? 1 : 0}>
        <thead>
          <tr>
            <th><TableHeaderTitle># orden</TableHeaderTitle></th>
            <th><TableHeaderTitle>Paciente</TableHeaderTitle></th>
            <th><TableHeaderTitle>Fecha</TableHeaderTitle></th>
            <th><TableHeaderTitle>Total</TableHeaderTitle></th>
            {/*props.isDoctor && (
              <th><TableHeaderTitle>Paciente</TableHeaderTitle></th>
            )*/}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((result, index) => (
            <tr key={index}>
              <td className='pt-4 pb-4'>
                <NormalText>
                  {result.folio}
                </NormalText>
              </td>
              <td className='pt-4 pb-4'>
                  <NormalText>
                    {result.nombre} {result.app}
                  </NormalText>
              </td>
              <td className='pt-4 pb-4'>
                <NormalText>
                  {result.created_at}
                </NormalText>
              </td>
              <td className='pt-4 pb-4'>
                <NormalText>
                  ${result.total} MXN
                </NormalText>
              </td>
              {/*props.isDoctor && (
                <td className='pt-4 pb-4'>
                  <NormalText>
                    {result.nombre} {result.app} {result.apm}
                  </NormalText>
                </td>
              )*/}
              <td className='pt-4 pb-4'>
                <DownloadText 
                isdoctor={props.isDoctor ? 1 : 0}
                href={`https://lpqsystem.com/downloadReporte?idCaso=${result.idLB}`}
                target='_blank'
                >
                  <div className='d-flex flex-row w-auto'>
                    <span className='d-block w-auto' style={{marginRight: '10px'}}>Descargar</span>
                    <UilDownloadAlt size={18} color={props.isDoctor ? '#4F90BA' : '#00AC90'} className='w-auto' />
                  </div>
                </DownloadText>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
}