import React, { useState } from 'react';
import { UisStar } from '@iconscout/react-unicons-solid';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { addCommentToPost } from '../../../actions/blog';
import { useDispatch } from 'react-redux';
import { AQUA, TEXT_BLACK } from '../colors';
import styled from 'styled-components';
import handleAlert from '../../../helpers/handleAlert';

const StyledDiv = styled.div`
  h2 {
    color: ${TEXT_BLACK};
    font-size: 20px;
    font-weight: 700;
    line-height: 125%;
    margin-top: 20px;
  }
  #inputs-div {
    background: #F3F3F3;
    padding: 19px 21px;
  }
  input, textarea {
    border: none;
  }
  button {
    background: ${AQUA};
    color: #fff;
    border: none;
    padding: 7px 20px;
    border-radius: 15px;
    font-size: 16px;
    margin: 10px 0 30px 10px;
    transition: all 0.3s ease;
    :hover {
      filter: brightness(1.15);
    }
  }
`;

export const NewComment = ({ postId }) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [rate, setRate] = useState(0);
  const dispatch = useDispatch();

  const handleRateClick = (rating) => {
    setRate(rating);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("🚀 ~ file: newComment.jsx:18 ~ handleSubmit ~ name, message, rate:", name, message, rate);
    if(name?.length >= 2 && message?.length >= 2){
      dispatch(addCommentToPost(postId, {
        name: name,
        message: message,
        rate: rate,
      }))
    }else{
      handleAlert('warning', 'Ingresa información válida');
    }
    // Call the handleCommentSubmit function with the comment details
    // handleCommentSubmit({ name, message, rate });
    // Reset the form fields
    // setName('');
    // setMessage('');
    // setRate(0);
  };

  return (
    <StyledDiv>
      <h2>Deja tu comentario</h2>
      <Form onSubmit={handleSubmit}>
        <div id='inputs-div'>
          <FloatingLabel controlId="name" label="Nombre" className="mb-3">
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="message" label="Comentario" className="mb-3">
            <Form.Control
              as="textarea"
              style={{ height: '100px' }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </FloatingLabel>
          <Form.Group className="mb-3">
            <div>
              {[1, 2, 3].map((rating) => (
                <UisStar
                  key={rating}
                  size="24"
                  onClick={() => handleRateClick(rating)}
                  color={rate >= rating ? AQUA : '#DDDDDD'}
                  style={{ cursor: 'pointer' }}
                />
              ))}
            </div>
          </Form.Group>
        </div>
        <button type="submit">Comentar</button>
      </Form>
    </StyledDiv>
  );
};