import React from 'react';
import styled from 'styled-components';
import { UilArrowRight } from '@iconscout/react-unicons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const StyledCard = styled.div`
  width: 100%;
  height: 230px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid #E9E9E9;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(71, 74, 75, 0.15);
  margin: 18px auto;
  position: relative;
  display: flex;
  width: 100%;
  .img-div {
    width: 306px;
    height: 230px;
    background: ${({color, banner}) => banner ? `url('${banner}')` : color ?? '#00AC90'};
    background-position: center;
    background-size: cover;
    border-radius: 16px;
  }
  hr {
    width: 34%;
    color: ${({color}) => color};
    opacity: 1;
  }
  .content-div {
    padding: 0 23px 10px;
    width: 100%;
  }
  .category {
    color: ${({color}) => color};
    font-family: Real Head Pro;
    font-size: 16px;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.16px;
    margin-bottom: 5px;
  }
  .date {
    color: #C4C4C4;
    font-family: Real Head Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.16px;
    margin-bottom: 5px;
  }
  .title {
    color: #474A4B;
    font-family: Faustina;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 129.63%;
    letter-spacing: 0.27px;
    margin-bottom: 10px;

    max-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .desc {
    color: #474A4B;
    font-family: IBM Plex Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
  .continue {
    padding: 7px 26px;
    background: #F2F7F9;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    transition: all ease 0.3s;
    :hover {
      background: ${({color}) => color};
      color: #FFF;
    }
  }
  @media screen and (width <= 768px) {
    padding-bottom: 40px;
    height: 250px;
    .img-div {
      display: none;
    }
    .desc {
      margin-bottom: 40px;
    }
  }
`;

export const NewBlogCardList = (props) => {
  console.log("🚀 ~ file: newBlogCardList.jsx:104 ~ NewBlogCardList ~ props:", props)
  const history = useHistory();

  return (
    <StyledCard color={props?.category?.color ?? '#00AC90'} banner={props?.data?.banner}>
      <div className='img-div'/>
      <div className='content-div'>
        <hr />
        <p className='category'>{props?.category?.name}</p>
        {/* <p className='date'>{timestampToSpanishDate(props?.data?.created) ?? ''}</p> */}
        <p className='title'>{props.data?.title}</p>
        <p className='desc'>TODO: La biopsia es un procedimiento diagnóstico por el cual se extrae una parte o la totalidad del tejido.</p>
      </div>
      <div className='continue' onClick={() => history.push(`/post/${props?.data?.id ?? 0}`)}>Seguir leyendo<UilArrowRight/></div>
    </StyledCard>
  );
};
