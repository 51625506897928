const studiesInclude = [
  {
    mainStudy: 101,
    studies: [103, 104, 105, 106, 34, 35, 36],
    mensaje:'Este estudio ya esta incluido en la Química sanguínea de 6 elementos '
  },
  {
    mainStudy: 26,
    studies: [29, 30, 975],
    mensaje:'Este estudio ya esta incluido en Tiempos de coagulación '
  },
  {
    mainStudy: 75,
    studies: [67, 14],
    mensaje:'Este estudio ya esta incluido en la Citometría Hemática con VSG '
  },
  {
    mainStudy: 100,
    studies: [103, 914, 106, 34],
    mensaje:'Este estudio ya esta incluido en la Química sanguínea de 3 elementos '
  },
  {
    mainStudy: 101,
    studies: [103, 914, 106, 34, 35, 36, 100],
    mensaje:'Este estudio ya esta incluido en la Química sanguínea de 6 elementos '
  },
  {
    mainStudy: 670,
    studies: [103, 914, 105, 106, 34, 35, 36, 101, 100],
    mensaje:'Este estudio ya esta incluido en la Química sanguínea de 10 elementos '
  },
  {
    mainStudy: 943,
    studies: [
      101, 103, 914, 105, 106, 34, 35, 36, 37, 39, 819, 48, 53, 58, 60, 62, 64,
      66, 69, 71, 73, 41, 49, 71, 637, 51, 52, 54, 45, 670, 100,
    ],
    mensaje:'Este estudio ya esta incluido en la Química sanguínea de 39 elementos '
  },
  {
    mainStudy: 618,
    studies: [14, 670, 31],
    mensaje:'Este estudio ya esta incluido en el Check Up básico '
  },
  {
    mainStudy: 624,
    studies: [14, 943, 31, 1234],
    mensaje:'Este estudio ya esta incluido en el Check Up completo '
  },
  {
    mainStudy: 59,
    studies: [51, 52, 54, 41, 73],
    mensaje:'Este estudio ya esta incluido en Electrolitos séricos completos'
  },
  {
    mainStudy: 926,
    studies: [51, 52, 54],
    mensaje:'Este estudio ya esta incluido en Electrolitos séricos parciales (Na, K, Cl, Ca)'
  },
  {
    mainStudy: 60,
    studies: [37, 58, 62, 64, 45],
    mensaje:'Este estudio ya esta incluido en Pruebas de función hepática'
  },
  {
    mainStudy: 363,
    studies: [37, 58, 62, 64, 29, 45, 49],
    mensaje:'Este estudio ya esta incluido en el Perfil hepático'
  },
  {
    mainStudy: 190,
    studies: [36],
    mensaje:'Este estudio ya esta incluido en el Perfil de lípidos'
  },
  {
    mainStudy: 639,
    studies: [103, 69, 39],
    mensaje:'Este estudio ya esta incluido en el Perfil pancreático'
  },
  {
    mainStudy: 636,
    studies: [635, 424, 179, 442, 695, 71, 879],
    mensaje:'Este estudio ya esta incluido en el Perfil de litiasis'
  },
  {
    mainStudy: 641,
    studies: [85, 933, 215, 142],
    mensaje:'Este estudio ya esta incluido en el Perfil de isquemia cardíaca'
  },
  {
    mainStudy: 224,
    studies: [238, 239, 244, 248, 253, 318, 319],
    mensaje:'Este estudio ya esta incluido en el Panel de drogas 7 parámetros (Antidoping)'
  },
  {
    mainStudy: 231,
    studies: [238, 239, 244, 248, 318, 319],
    mensaje:'Este estudio ya esta incluido en el Panel de drogas 10 parámetros (Antidoping)'
  },
  {
    mainStudy: 595,
    studies: [546, 329, 553, 552],
    mensaje:'Este estudio ya esta incluido en el Perfil Coprológico (Adultos)'
  },
  {
    mainStudy: 596,
    studies: [546, 329, 553, 552, 548, 333],
    mensaje:'Este estudio ya esta incluido en el Perfil Coprológico (Niños)'
  },
  {
    mainStudy: 116,
    studies: [118, 170, 175, 366, 171, 174, 173, 172],
    mensaje:'Este estudio ya esta incluido en el Perfil tiroideo'
  },
  {
    mainStudy: 117,
    studies: [118, 170, 175, 366, 171, 174],
    mensaje:'Este estudio ya esta incluido en Pruebas de función tiroidea'
  },
  {
    mainStudy: 368,
    studies: [173, 172],
    mensaje:'Este estudio ya esta incluido en Anticuerpos antitiroideos'
  },
  {
    mainStudy: 113,
    studies: [107, 111, 108, 109, 110],
    mensaje:'Este estudio ya esta incluido en el Perfil ginecológico'
  },
  {
    mainStudy: 894,
    studies: [108, 107, 109],
    mensaje:'Este estudio ya esta incluido en el Perfil hormonal ovulatorio I'
  },
  {
    mainStudy: 895, // This and the 612 is the same study
    studies: [110, 109, 108, 107, 612],
    mensaje:'Este estudio ya esta incluido en el Perfil hormonal ovulatorio II'
  },
  {
    mainStudy: 855,
    studies: [108, 856, 118, 599, 109, 107],
    mensaje:'Este estudio ya esta incluido en el Perfil hipofisiario'
  },
  {
    mainStudy: 706,
    studies: [75, 57, 525, 34, 819],
    mensaje:'Este estudio ya esta incluido en el Perfil Reumático'
  },
  {
    mainStudy: 672,
    studies: [138, 139, 570],
    mensaje:'Este estudio ya esta incluido en el Perfil de andrógenos'
  },
  {
    mainStudy: 539,
    studies: [108, 109, 138, 139, 111],
    mensaje:'Este estudio ya esta incluido en el Perfil hormonal masculino'
  },
  {
    mainStudy: 647,
    studies: [499, 501],
    mensaje:'Este estudio ya esta incluido en el Perfil Prenupcial'
  },
  {
    mainStudy: 643,
    studies: [565, 620, 566],
    mensaje:'Este estudio ya esta incluido en el Perfil Celíaco'
  },
  {
    mainStudy: 626,
    studies: [609, 610, 611, 606, 607, 608, 656, 657, 658, 707, 708, 709, 710, 761],
    mensaje:'Este estudio ya esta incluido en el Perfil TORCH'
  },
  {
    mainStudy: 189,
    studies: [177, 143, 439],
    mensaje:'Este estudio ya esta incluido en el Perfil de hierro'
  },
  {
    mainStudy: 383,
    studies: [387, 390, 391, 577, 567, 631, 393, 396],
    mensaje:'Este estudio ya esta incluido en el Panel completo de hepatitis'
  },
  {
    mainStudy: 581, //panel viral 1
    studies: [4, 1021, 1022],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral tipo 1 por qPCR'
  },
  {
    mainStudy: 582, //panel viral 2
    studies: [4],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral tipo 2 por qPCR'
  },
  {
    mainStudy: 1024, // triple antigeno
    studies: [673, 1023, 191],
    mensaje:'Este estudio ya esta incluido en Triple antígeno: Influenza + VSR + Covid19'
  },
  {
    mainStudy: 1025, // panel respiratorio
    studies: [584, 581],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 1 + Bacteriano por PCR'
  },
  {
    mainStudy: 1026, // panel respiratorio
    studies: [931, 581],
    mensaje:'Este estudio ya esta incluido en el Panel respitatorio viral Tipo 1 + Tipo 2 por PCR'
  },
  {
    mainStudy: 1027, // panel respiratorio
    studies: [583, 581],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 1 + Tipo 3 por PCR'
  },
  {
    mainStudy: 1028, // panel respiratorio
    studies: [931, 583],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 2 + Tipo 3 por PCR'
  },
  {
    mainStudy: 1029, // panel respiratorio
    studies: [931, 584],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 2 + Bacteriano por PCR'
  },
  {
    mainStudy: 1030, // panel respiratorio
    studies: [583, 584],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 3 + Bacteriano por PCR'
  },
  {
    mainStudy: 1031, // panel viral completo
    studies: [4, 1021, 1022, 581, 931, 583],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral completo por PCR'
    //pcr,influ,vsr,1,2,3
  },
  {
    mainStudy: 1032, // panel triple respiratorio
    studies: [584, 581, 931],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 1 + 2 + bacteriano por PCR'
  },
  {
    mainStudy: 1033, // panel triple respiratorio
    studies: [584, 581, 583],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 1 + 3 + Bacteriano por PCR'
  },
  {
    mainStudy: 1034, // panel triple respiratorio
    studies: [584, 583, 931],
    mensaje:'Este estudio ya esta incluido en el Panel respiratorio viral Tipo 2 + 3 + Bacteriano por PCR'
  },
  {
    mainStudy: 677, // panel total
    studies: [4, 1021, 1022, 1032, 1033, 1034, 581, 931, 583, 584],
    mensaje:'Este estudio ya esta incluido en Relación Albúmina/Globulina'
    //pcr,influ,vsr,1,2,3,bact
  },
  {
    mainStudy: 1234, // 35 elementos
    studies: [100, 101, 670],
    mensaje:'Este estudio ya esta incluido en la Química sanguínea de 35 elementos'
  },
  {
    mainStudy: 115, // Antígeno
    studies: [114],
    mensaje:'Este estudio ya esta incluido en Antígeno Prostático Total (PSA Total)'
  },
];

export const studyInCart = (item, cartItems) => {
  // Check if it's directly in the cart
  const itemInCart = cartItems.find((cartItem) => cartItem.id === item.id);
  if (itemInCart) {
    return {
      exists: true,
      message: "Este estudio ya lo tienes agregado en tu solicitud.",
    };
  }

  // Check if there's a package with the study or opposite way
  for (const studyInclude of studiesInclude) {
    if (studyInclude.mainStudy === item.id) {
      const cartItem = cartItems.find((cartItem) =>
        studyInclude.studies.includes(cartItem.id)
      );
      if (cartItem) {
        return {
          exists: true,
          message: "Ya tienes un estudio agregado que esta incluido en este paquete",
        };
      }
    } else if (studyInclude.studies.includes(item.id)) {
      const cartItem = cartItems.find(
        (cartItem) => cartItem.id === studyInclude.mainStudy
      );
      if (cartItem) {
        return {
          exists: true,
          message: studyInclude.mensaje,
        };
      }
    }
  }

  // In case is not in cart any of the ways
  return { exists: false, message: "" };
};

export const UIDsHome = [
  "vAIJKJ1QZFPsQRECp14pfht6buz2", //servicios
  "v2g9SPEEwlSV1gPvXZIlbLbkho92", //drluis
  "xV1FBGAHGLSFJeYoLItJGfdT6dH3", //freddy
  "2cjZo5dwkadPojHr0xDAyzuAwvH3", //dr carlos num
  "QKRM85cNr5QKXGJ9JF87YpsDXpL2", //atencion
  "EMBlAzUkUSOGauTwVr7n4HjliQq1", //coordinacion
  "mp3EP3WrR1hHULdWM0pyoBlwDdm1", //freddy
  "Lmiu5H8QncYZ0q0RUcDptCpxn923", //gely maria
  "VE6ej880b8a6QJmZI7eUGzDFzyk1", //palmas
  "oraEV2JuBeYYSLZv42rEP4Oggw43", //joshua
  "FZpBUV5jFeMGkRNHnKJFaMtlohn1", //judith
  "pycNOqNBMzaRfHHI8P3mtLcNm0N2", //torres
  "DWNqQLdBX2e2wwDlaQQwHjL5XQz1", //bosques
  "ytyo4yCpwvQBVKRsAI9lcFQ36lJ2", //dr carlos
  "uSxemKfEHvVnvHxmFJHvI4bgoUq1", //la paz
  "O4G3XcBc0uPHmJdLbDWbHhzrHip1", // sergio
  "osQlCTQrEOX43QbkoyHXo1stS0a2", //cynthia
];

// Transform number to currency
export const currency = function (number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(number);
};
