import React, { useState, useEffect } from 'react';
import axios from "axios";
import styled from 'styled-components';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import { UserResults } from './userResults';
import { TableResults } from './resultsTable';

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  font-style: normal;
  color: #697077;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const TabIndicatorCol = styled(Col)`
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
  background: ${(props) =>
    props.selected && props.$isDoctor
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : props.selected &&
        "linear-gradient(83.81deg, #F7EE1B -32.78%, #35E19B 24.37%, #00AE8E 75.27%, #5097E3 138.67%)"};
`;

const TabTitle = styled.a`
  display: inline-block;
  width: 100%;
  margin-top: -10px;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  color: ${(props) => (props.$isDoctor ? "#4F90BA" : "#00AC90")};
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  transition: 200ms;
  :hover {
    color: ${(props) => (props.$isDoctor ? "#5097E3 " : "#35E19B")};
    transition: 200ms;
  }
`;

const StyledNav = styled.nav`
  border-bottom: 1px solid #c1d9d8;
`;

const resultsDatal = [
  {
    idSolicitud: 1,
    idLpq: 2,
    idSass: 3,
    estatus: 4,
    fase: 6,
    fecha: '06/07/2022',
    total: '1,199',
    paciente: 'Macaquinho Castañeda',
  },
  {
    fecha: '02/07/2022',
    total: '400',
    paciente: 'Macaquinho Hernández',
  },
  {
    fecha: '27/06/2022',
    total: '600',
    paciente: 'Random name',
  },
  {
    fecha: '11/01/2022',
    total: '1,199',
    paciente: 'Random name II',
  },
  {
    fecha: '22/09/2021',
    total: '2,450',
    paciente: 'Random name III',
  },
  {
    fecha: '17/02/2021',
    total: '1,199',
    paciente: 'Random name IV',
  },
];

const NoResultsDiv = styled.div`
  width: 100%;
  height: 518px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: #F3F3F3;
    width: 100px;
    height: 100px;
    margin-bottom: 32px;
  }
  p {
    color: #474A4B;
    text-align: center;
    font-size: 18px;
  }
`;

export const Results = () => {
  const [tab, setTab] = useState(1);
  const [results, setResults] = useState([]);
  const user = useSelector((state) => state.user?.data?.data);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const uid = useSelector((state) => state?.auth?.uid);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  useEffect(() => {
    dd();
  }, []);

  const dd = async () => {
    try {
      //setLoading(true);
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/misResultadosDR?user=${uid}`
      );
      setResults(requestPending.data);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.log(error);
    }
  };

  return (
    <Container
      className={isMobile ? 'mt-5 pt-4' : ''}
      style={{ minHeight: '100vh' }}
    >
      <HelmetTitle title='Resultados' name='description' content='content' />
      <Row className='mb-5'>
        <HeaderSection>
          <HeaderTitle className='mb-5'>Mis Resultados</HeaderTitle>
          {results?.length > 0 &&
            <StyledNav>
              <Row className='m-0 p-0'>
                {/*<Col xs={6} md={3} lg={3} xl={2} style={{ paddingLeft: '0' }}>
                  <TabTitle
                    onClick={() => setTab(0)}
                    selected={tab === 0 ? true : false}
                    $isDoctor={isDoctor}
                  >
                    {isDoctor ? 'Resultados' : 'Mi última visita'}
                    <TabIndicatorCol
                      onClick={() => setTab(0)}
                      selected={tab === 0 ? true : false}
                      $isDoctor={isDoctor}
                    />
                  </TabTitle>
                </Col>*/}
                <Col xs={6} md={3} lg={3} xl={2} style={{ paddingLeft: '0' }}>
                  <TabTitle
                    onClick={() => setTab(1)}
                    selected={tab === 1 ? true : false}
                    $isDoctor={isDoctor}
                  >
                    {isDoctor ? 'Solicitudes generadas' : 'Historial'}
                    <TabIndicatorCol
                      onClick={() => setTab(1)}
                      selected={tab === 1 ? true : false}
                      $isDoctor={isDoctor}
                    />
                  </TabTitle>
                </Col>
              </Row>
            </StyledNav>
          }
        </HeaderSection>
      </Row>
      {results?.length > 0 ?
        <>
        {tab === 0 && (
          <Row className='mb-5'>
            {isDoctor ? (
              <TableResults data={[]} isDoctor={isDoctor} />
            ) : (
              <UserResults data={[]} tab={tab} isDoctor={isDoctor} />
            )}
          </Row>
        )}
        {tab === 1 && (
          <Row className='mb-5'>
            {isDoctor ? (
              <TableResults data={results} isDoctor={isDoctor} />
            ) : (
              <UserResults data={results} tab={tab} isDoctor={isDoctor} />
            )}
          </Row>
        )}
        </>
        :
        <NoResultsDiv>
          <div><UilClipboardAlt size={50} color='#C4C4C4'/></div>
          <p>No hay resultados pendientes.</p>
        </NoResultsDiv>
      }
    </Container>
  );
};
