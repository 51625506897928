import React, { useEffect, useState } from 'react';
import { getCommentsForPost } from '../../../actions/blog';
import styled from 'styled-components';
import { UimStar } from '@iconscout/react-unicons-monochrome';

const StyledDiv = styled.div`
  max-height: 300px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const StyledComment = styled.div`
  border-top: 1px solid #E0ECEC;
  border-bottom: 1px solid #E0ECEC;
  padding: 9px 0 24px;
  h4 {
    color: var(--Negro, #474A4B);
    font-size: 20px;
    font-weight: 700;
    line-height: 25px; /* 125% */
  }
  .comment {
    color: var(--Negro, #474A4B);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 161.111% */
  }
  .rate {
    color: var(--Grayscale-02, #C4C4C4);
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .rate-div {
    display: flex;
    align-items: center;
  }
`;

export const Comments = ({postId}) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const comments = await getCommentsForPost(postId);
      console.log("🚀 ~ file: post.jsx:226 ~ fetchComments ~ comments:", comments)
      setComments(comments);
    } catch (error) {
      // Handle error
      console.error('Error fetching posts without comments: ', error);
    }
  };

  return (
    <StyledDiv>
      {/* Comments */}
      {comments?.map((comment, index) =>
        <StyledComment key={index}>
          <h4>{comment?.name}</h4>
          <p className='comment'>{comment?.message}</p>
          <div className='rate-div'>
            <p className='rate'>Calificación</p>
            <div>
            {Array.from(new Array(Math.round(comment?.rate ?? 0))).map((_, i) => (
              <UimStar key={i} size={24} color={'#30DF99'}/>
            ))}
            </div>
          </div>
        </StyledComment>
      )}
    </StyledDiv>
  )
}